import { Box, Theme } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarFilterButton } from "@mui/x-data-grid-pro-v5";
import {
  CustomDateColumnType,
  CustomStringColumnType,
} from "@next/constants/data-grid-pro-constants-v5";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { ProjectOrderTable } from "@next/modules/project/components/order/project-order-table";
import { selectProjectCompanyOrdersLoading } from "@next/modules/project/redux/selectors";
import i18n from "assets/configi18n/i18n";
import React, { ComponentProps, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBuyerRFQSTableColumns } from "./buyer-rfqs-table.columns";
import { getCompany } from "services/profile/profile.selectors";
import { REQUESTS_TABLE_NAVIGATION_KEY } from "../../pages/buyer-rfqs/buyer-rfqs";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import { useCompanyPlan } from "@next/hooks/useIsPremiumCompany";
import { createStyles, makeStyles } from "@mui/styles";
import useTableFilterAndSort from "@next/hooks/useTableFilterAndSort-v5";
import { getSortQueryString } from "./buyer-rfqs-table.helpers";
import useLocalStorageGridState from "@next/hooks/useLocalStorageGridState-v5";
import { BUYER_RFQ_GRID_COLUMN_STATE } from "@next/constants/data-grid-state-constants";
import { SharedInternalNotesModal } from "@next/modals/shared-internal-notes-modal";
import { INTERNAL_NOTES_MODULE_TYPE } from "@next/constants";
import { NoteType, workspaceNextActions } from "../../redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: 10,
    },
    toolbarItem: {
      color: "primary",
    },
    dataGrid: {
      borderTop: "none",
    },
  })
);

type Props = {
  query: string;
  isContractQuery?: boolean;
  isCancelledQuery?: boolean;
  selectedNote?: NoteType[];
};

const GridToolbarColumnsButtonCustom = (
  props: Partial<ComponentProps<typeof GridToolbarColumnsButton>>
) => {
  return (
    <GridToolbarColumnsButton
      placeholder=""
      {...(props as ComponentProps<typeof GridToolbarColumnsButton>)}
    />
  );
};

const GridToolbarFilterButtonCustom = (
  props: Partial<ComponentProps<typeof GridToolbarFilterButton>>
) => {
  return (
    <GridToolbarFilterButton
      placeholder=""
      {...(props as ComponentProps<typeof GridToolbarFilterButton>)}
    />
  );
};

const BuyerRfqsTable: React.FC<Props> = ({
  query,
  isContractQuery = false,
  isCancelledQuery = false,
  selectedNote,
}) => {
  const dispatch = useDispatch();
  const { savedTableQueryPagination } = useTableSavedQueryPagination(REQUESTS_TABLE_NAVIGATION_KEY);
  const companyOrdersLoading = useSelector(selectProjectCompanyOrdersLoading);
  const [buyerRfqQuery, setBuyerRfqQuery] = useState<string>(query);

  const { handleSortChange, handleFilterChange, sortQueryString, sortModel, filterModel } =
    useTableFilterAndSort(getSortQueryString);

  useEffect(() => {
    //fetch notes list
    dispatch(workspaceNextActions.getInternalNotesRequest(INTERNAL_NOTES_MODULE_TYPE.RFQ));
  }, []);

  useEffect(() => {
    if (selectedNote.length > 0) {
      let filterQuery = `&notes=${selectedNote.map((item: any) => item?.id).join(",")}`;
      setBuyerRfqQuery((previous_query) => previous_query.concat(filterQuery));
    } else {
      setBuyerRfqQuery(query);
    }
  }, [selectedNote]);

  useEffect(() => {
    setBuyerRfqQuery(query);
  }, [query]);

  // const gridApiRef = useGridApiRef();
  const { apiRef, restoreState } = useLocalStorageGridState(BUYER_RFQ_GRID_COLUMN_STATE);
  const classes = useStyles();
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const company = useSelector(getCompany);

  const isFreemiumBuyer = useCompanyPlan("freemium");

  const buyerRFQSTableColumns = useMemo(
    () =>
      restoreState(
        getBuyerRFQSTableColumns(
          hasViewerRole,
          isContractQuery,
          company?.is_order_management_enabled,
          isCancelledQuery,
          savedTableQueryPagination?.lsr,
          isFreemiumBuyer,
          INTERNAL_NOTES_MODULE_TYPE.RFQ
        )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      companyOrdersLoading,
      hasViewerRole,
      isContractQuery,
      i18n?.language,
      company?.is_order_management_enabled,
      restoreState,
    ]
  );
  return (
    <>
      <ProjectOrderTable
        rfqDetailOpenVariant="inline"
        dataGridProProps={{
          columns: buyerRFQSTableColumns,
          rowHeight: 56,
          getRowClassName: () => `c-cursor-pointer`,
          sortingMode: "server",
          sortModel,
          onSortModelChange: handleSortChange,
          filterMode: "server",
          filterModel,
          onFilterModelChange: handleFilterChange,
          apiRef: apiRef,
          columnTypes: {
            string: CustomStringColumnType,
            date: CustomDateColumnType,
          },
          components: {
            Toolbar: () => (
              <Box className={classes.toolbar}>
                {!hasViewerRole ? (
                  <GridToolbarColumnsButtonCustom
                    className={classes.toolbarItem}
                    onResize={undefined}
                    onResizeCapture={undefined}
                  />
                ) : null}
                <GridToolbarFilterButtonCustom
                  className={classes.toolbarItem}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />
              </Box>
            ),
          },
          className: classes.dataGrid,
        }}
        query={buyerRfqQuery}
        refetchDataAfterCancelRfq={true}
        refetchDataAfterExtendRfq={true}
        sortQueryString={sortQueryString}
        filterValues={filterModel.items}
        savedTableQueryKey={REQUESTS_TABLE_NAVIGATION_KEY}
      />
      <SharedInternalNotesModal moduleType={INTERNAL_NOTES_MODULE_TYPE.RFQ} />
    </>
  );
};

export default React.memo(BuyerRfqsTable);
