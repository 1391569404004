import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import CurrencyField from "components/common/fields/CurrencyField";
import ConfirmationDialog from "@next/components/confirmation-dialog/confirmation-dialog";
import { QuotationExtraFieldsTable } from "@next/modules/marketplace/components/quotation/quotation-extra-fields-table";
import { getCompany } from "services/profile/profile.selectors";
import {
  checkIsAirbusA220Company,
  checkIsShowCommissionFeeCompany,
} from "@next/utils/checkCompanyUtils";
import { useReactToPrint } from "react-to-print";
import { QuotationPDFViewer } from "@next/components/quotation-pdf-viewer";
import useStateCallback from "@next/hooks/useStateCallback";
import { QuoteTotalPrice } from "../../quote-total-price";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { modalsActions } from "@next/redux/modalsSlices";
import { withStyles } from "@mui/styles";
import CustomButton from "@next/components/custom-button/custom-button";
import { getErpConfiguration } from "services/part-configuration/part-configuration.selectors";
import { ErpConfigurationType } from "@next/modules/app/components/app-bar-next/app-bar-next-erp-configuration";
import SyncIcon from "@mui/icons-material/Sync";
import { selectSyncERPQuotationLoading } from "@next/modules/workspace/redux/selectors";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { getLeadTimeDelayUnit } from "@next/components/lead-time/lead-time.definitions";
import EditIcon from "@mui/icons-material/Edit";
import UpdateIcon from "@mui/icons-material/Update";
import moment from "moment";
import { formatDateTimeAsCalender } from "@next/utils/dateTimeUtils";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { Status } from "@next/components/state-status-tag/state-status-tag.utils";
import { AppThemeV5 } from "layout/AppTheme";
import { DiscrepancyTag } from "@next/modules/workspace/components/ai-parse-quote-status-tag/ai-parse-quote-status-tag";
import SyncQuotesToggle from "@next/modules/workspace/components/sync-quotes-toggle/sync-quotes-toggle";

/**
 * use this function to get specific feature of parts
 * @param {Array} listOfPart
 */
export const getFeatureOfPart = (listOfPart, fixedFees, pdfParsingResult) => {
  return listOfPart.map((part) => {
    let partUnitPrice;
    let partPrice;
    let partAIParsingWarning =
      pdfParsingResult && pdfParsingResult?.unmatched_rfq_part_pks?.includes(part.part_id);

    if (part.price != null) {
      partPrice = Number.parseFloat((part.price * part.quantity).toString()).toFixed(2);
    }

    if (part.price != null) {
      partUnitPrice = Number.parseFloat((partPrice / part.quantity).toString()).toFixed(2);
    }

    let data = {
      name: part.name,
      quantity: part.quantity,
      price: partPrice,
      unitPrice: partUnitPrice,
      fixedFees: fixedFees,
      partAIParsingWarning: partAIParsingWarning,
    };

    return data;
  });
};

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 300,
    fontSize: 12,
    marginTop: 5,
    textAlign: "center",
  },
}))(Tooltip);

type Props = {
  quoteIndex: number;
  quote: any;
  token: string;
  activeRfq: any;
  selectWinner: any;
  t: any;
  pdfParsingResult: any;
};

export const OffersDetails = ({
  quoteIndex,
  selectWinner,
  token,
  quote,
  activeRfq,
  pdfParsingResult,
  t,
}: Props) => {
  const dispatch = useDispatch();
  const company = useSelector(getCompany);
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const erpConfiguration = useSelector(getErpConfiguration);
  const syncERPQuotationLoading = useSelector(selectSyncERPQuotationLoading);
  const activeRFQDetails = useSelector(getRfqDetails);
  const isShowCommissionFeeCompany = checkIsShowCommissionFeeCompany(company?.id);
  const isAwarded = [Status.CONTRACT, Status.SPLITTED].includes(activeRFQDetails?.status);
  const hideEditQuoteBtn =
    hasViewerRole ||
    checkIsAirbusA220Company(company?.id) ||
    (isAwarded && erpConfiguration?.erp_type?.value === "GeniusERP");
  const isInforErp = erpConfiguration?.erp_type?.value === ErpConfigurationType.INFOR;
  const [syncContract, setSyncContract] = React.useState(true);

  const [showPDF, setShowPDF] = useStateCallback(false);
  const pdfComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => pdfComponentRef.current,
    onAfterPrint: () => setShowPDF(false),
    documentTitle: `${activeRfq.rfq_display_name}_Quote_${activeRfq.quotations[quoteIndex].pk}`,
  });

  const quoteTotalPrice =
    quote?.total_price !== null ? Number.parseFloat(quote?.total_price) : undefined;

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const featuresOfParts = getFeatureOfPart(quote.parts, quote.fixed_charge, pdfParsingResult); // get the specific propriety of part i want to display

  const subTotal = useMemo(
    () =>
      featuresOfParts.reduce((acc, part) => {
        return acc + parseFloat(part.price || "0");
      }, 0),
    [featuresOfParts]
  );
  const last_rfq_synchronized = activeRFQDetails.erp_sync_date
    ? activeRFQDetails?.erp_sync_date
    : "";

  const show_last_rfq_synchronized =
    moment(last_rfq_synchronized).valueOf() - moment(activeRfq?.winner_date).valueOf() > 0;

  // PM user: Opens the chat box to private message user
  const openQuotationThreadChatBox = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.CONTACT_SUPPLIERS_MODAL,
        data: {
          supplierId: quote.company_info.company_pk,
        },
      })
    );
  };

  // When the buyer is satisfied for the seller proposition it can select the winner
  const selectManufacturer = (sync_vend_contracts) => {
    const theCompanyPk = {
      winner_company_pk: quote.company_info.company_pk,
      sync_vend_contracts: isInforErp ? sync_vend_contracts : undefined,
    };

    setIsConfirmationDialogOpen(false);

    selectWinner(token, theCompanyPk, activeRfq.pk);
  };

  const handleCloseDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onClickSyncWithSyteline = () => {
    dispatch(workspaceNextActions.syncERPQuotationRequest({ quotation_id: quote.pk }));
  };

  const handleEditQuote = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.UPLOAD_QUOTATION_AS_BUYER_MODAL,
        data: {
          supplier_id: quote?.company_info?.company_pk,
          supplier_picture: quote?.company_info?.company_picture,
          parts: quote?.parts?.length > 0 ? quote?.parts : activeRfq?.parts,
          currency: quote?.currency,
          delay: quote?.delay,
          delay_unit: quote?.delay_unit,
          files: quote?.quotation_uploads_list,
          fixed_charge: quote?.fixed_charge,
          total_price: quote?.total_price,
          quotation_id: quote?.pk,
          rfq_id: activeRfq?.pk,
          pdf_parsing_result: pdfParsingResult,
        },
      })
    );
  };

  return (
    <Grid container direction="column" spacing={2} className="c-offer-details">
      <Grid item>
        {!activeRfq.quotation_extra_fields && (
          <List component="nav" aria-labelledby="nested-bids-subheader">
            <ListItem divider sx={{ mb: "0px" }}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={3}>
                  <Box
                    fontSize={14}
                    style={{ color: "#000", opacity: "60%" }}
                    fontWeight="fontWeightRegular"
                    textAlign="left"
                  >
                    {t("workspace:partOfferView")}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    fontSize={14}
                    style={{ color: "#000", opacity: "60%" }}
                    fontWeight="fontWeightRegular"
                    textAlign="center"
                  >
                    {t("workspace:quantity")}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    fontSize={14}
                    style={{ color: "#000", opacity: "60%" }}
                    fontWeight="fontWeightRegular"
                    textAlign="center"
                  >
                    {t("workspace:unitPrice")}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    fontSize={14}
                    style={{ color: "#000", opacity: "60%" }}
                    fontWeight="fontWeightRegular"
                    textAlign="right"
                  >
                    {t("workspace:total")}
                  </Box>
                </Grid>
              </Grid>
            </ListItem>
            {featuresOfParts.map((part) => {
              return (
                <ListItem
                  divider
                  sx={{
                    backgroundColor: part.partAIParsingWarning
                      ? alpha(AppThemeV5.palette.warning.main, 0.1)
                      : "",
                    mb: "0px",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={3}>
                      <Box
                        textAlign="left"
                        fontSize={16}
                        className="c-part-name"
                        fontWeight="fontWeightRegular"
                      >
                        {part?.name}
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        textAlign="center"
                        fontWeight="fontWeightMedium"
                        fontSize={16}
                        style={{ color: "#000", opacity: "87%" }}
                      >
                        {part?.quantity}
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        textAlign="center"
                        fontWeight="fontWeightMedium"
                        fontSize={16}
                        style={{ color: "#000", opacity: "87%" }}
                      >
                        {part?.unitPrice != null ? (
                          <CurrencyField
                            value={part.unitPrice}
                            currency={quote.currency}
                            showSymbol
                          />
                        ) : (
                          <>
                            <span className="blurred-content"></span>$
                          </>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        textAlign="right"
                        fontWeight="fontWeightMedium"
                        fontSize={16}
                        style={{ color: "#000", opacity: "87%" }}
                      >
                        {part?.price != null ? (
                          <CurrencyField value={part?.price} currency={quote.currency} showSymbol />
                        ) : (
                          <>
                            <span className="blurred-content"></span>$
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </List>
        )}
        {activeRfq.quotation_extra_fields && (
          <QuotationExtraFieldsTable
            activeQuote={{
              ...activeRfq,
              parts: quote.parts,
              quote: {
                quotationId: quote.pk,
                company_name: quote?.company_info?.company_name,
                rfq_display_name: quote?.rfq_info?.rfq_display_name,
              },
            }}
            partExtraFields={activeRfq?.part_extra_fields}
          />
        )}
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Grid item xs={6}>
              {!activeRfq.quotation_extra_fields && (
                <List component="nav" aria-label="main mailbox folders">
                  {quote?.lead_time && quote?.lead_time.split(" ")[0] !== "0" && (
                    <ListItem divider>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                          <Box
                            fontSize={16}
                            style={{ color: "#000", opacity: "87%" }}
                            fontWeight="fontWeightRegular"
                          >
                            {t("workspaceNext:QA:topicPortal:leadTime")}
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            fontSize={16}
                            style={{ color: "#000", opacity: "87%" }}
                            fontWeight="fontWeightMedium"
                          >
                            {`${quote.delay} ${
                              getLeadTimeDelayUnit(Number(quote.delay), quote.delay_unit) || ""
                            }`}
                          </Box>
                        </Grid>
                      </Grid>
                    </ListItem>
                  )}
                  <ListItem divider>
                    <Grid container direction="row" justifyContent="space-between">
                      <Grid item>
                        <Box
                          fontSize={16}
                          style={{ color: "#000", opacity: "87%" }}
                          fontWeight="fontWeightRegular"
                        >
                          {t("workspace:subTotal")}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          fontSize={16}
                          style={{ color: "#000", opacity: "87%" }}
                          fontWeight="fontWeightMedium"
                        >
                          {quoteTotalPrice != null ? (
                            <CurrencyField value={subTotal} currency={quote.currency} showSymbol />
                          ) : (
                            <>
                              <span className="blurred-content"></span>$
                            </>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider>
                    <Grid container direction="row" justifyContent="space-between">
                      <Grid item>
                        <Box
                          fontSize={16}
                          style={{ color: "#000", opacity: "87%" }}
                          fontWeight="fontWeightRegular"
                        >
                          {t("inbox:fixedFees")}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          fontSize={16}
                          style={{ color: "#000", opacity: "87%" }}
                          fontWeight="fontWeightMedium"
                        >
                          {quote?.fixed_charge != null ? (
                            <CurrencyField
                              value={quote.fixed_charge}
                              currency={quote.currency}
                              showSymbol
                            />
                          ) : (
                            <>
                              <span className="blurred-content"></span>$
                            </>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </ListItem>
                  {isShowCommissionFeeCompany && quote?.commission_fee && (
                    <ListItem divider>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                          <Box
                            fontWeight="fontWeightRegular"
                            className="c-offer-details__commissionFees"
                          >
                            {`${t("pay:paymentTerms:commissionFees")} `}
                            {quote?.commission_fee?.percentage ? (
                              <span className="s-span">
                                {`(${quote?.commission_fee?.percentage}%)`}
                                <Tooltip
                                  title={`${t("pay:paymentTerms:commissionFeesInfo", {
                                    buyer_name: company?.name,
                                  })}`}
                                >
                                  <Avatar className="s-span__avatar">i</Avatar>
                                </Tooltip>{" "}
                              </span>
                            ) : (
                              <span className="blurred-content"></span>
                            )}
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            fontWeight="fontWeightMedium"
                            fontSize={16}
                            style={{ color: "#000", opacity: "87%" }}
                          >
                            {quote?.commission_fee && quote?.commission_fee?.amount != null ? (
                              <CurrencyField
                                value={quote?.commission_fee?.amount}
                                currency={quote.currency}
                                showSymbol
                              />
                            ) : (
                              <>
                                <span className="blurred-content"></span>$
                              </>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </ListItem>
                  )}
                  <ListItem divider>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Grid item display={"flex"} alignItems={"center"} gap={"8px"}>
                        <Box fontWeight="500" fontSize={20} style={{ color: "#000" }}>
                          {t("workspace:total")}
                        </Box>
                        {pdfParsingResult?.total_price?.is_equal === false && <DiscrepancyTag />}
                      </Grid>
                      <Grid item>
                        <Box textAlign="right" fontWeight="500" fontSize={20} color="#000000DE">
                          <QuoteTotalPrice
                            price={
                              isShowCommissionFeeCompany && quote?.commission_fee
                                ? quote?.commission_fee?.final_total_price
                                : quoteTotalPrice
                            }
                            currency={quote.currency}
                            showSymbol
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem divider>
                    <Grid container direction="row" justifyContent="space-between">
                      <Grid item>
                        <Box
                          fontWeight="fontWeightRegular"
                          fontSize={16}
                          style={{ color: "#000", opacity: "87%" }}
                        >
                          {t("pay:paymentTerms:paymentTermsLabel")}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          fontWeight="fontWeightMedium"
                          fontSize={16}
                          style={{ color: "#000", opacity: "87%" }}
                        >
                          {quote?.net_d["name"]}
                        </Box>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              )}
            </Grid>

            <Grid
              style={{ marginTop: "1rem" }}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems={"center"}
            >
              <Grid item>
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="secondary"
                  onClick={openQuotationThreadChatBox}
                >
                  {t("rfq:quotations:contact")}
                </Button>
              </Grid>

              <Box m={1} />
              {activeRfq.quotation_extra_fields ? null : (
                <>
                  <Grid item>
                    <HtmlTooltip
                      title={
                        activeRfq?.hide_quotation_sensitive_data
                          ? t("rfq:quotations:toolTipExportQuotationsPDFDisabled")
                          : ""
                      }
                    >
                      <span>
                        <Button
                          variant="outlined"
                          fullWidth
                          size="large"
                          color="secondary"
                          onClick={() => setShowPDF(true, handlePrint)}
                          disabled={activeRfq?.hide_quotation_sensitive_data}
                        >
                          {t("rfq:quotations:exportQuotation")}
                        </Button>
                      </span>
                    </HtmlTooltip>
                  </Grid>
                  <Box m={1} />
                </>
              )}

              {erpConfiguration?.erp_type?.value === ErpConfigurationType.INFOR &&
              quote?.is_awarded ? (
                <>
                  <Grid item>
                    <span>
                      <CustomButton
                        variant="contained"
                        fullWidth
                        size="large"
                        color="primary"
                        onClick={onClickSyncWithSyteline}
                        startIcon={<SyncIcon />}
                        loading={syncERPQuotationLoading}
                        loadingIndicatorPosition="start"
                      >
                        {t("rfq:quotations:syncWithSyteline")}
                      </CustomButton>
                    </span>
                  </Grid>
                  <Box m={1} />
                </>
              ) : null}

              {hideEditQuoteBtn ? null : (
                <>
                  <Grid item>
                    <Button
                      fullWidth
                      size="large"
                      variant="outlined"
                      color="secondary"
                      onClick={handleEditQuote}
                      startIcon={<EditIcon />}
                    >
                      {t("rfq:quotations:editQuote")}
                    </Button>
                  </Grid>

                  <Box m={1} />
                </>
              )}

              {quote?.is_awarded &&
              erpConfiguration?.erp_type?.value === ErpConfigurationType.GENIUS ? (
                <>
                  <Grid item>
                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                      <UpdateIcon />
                      {show_last_rfq_synchronized ? (
                        <Typography variant="body2">
                          {t("rfq:quotations:syncedAt", {
                            date: formatDateTimeAsCalender(last_rfq_synchronized),
                          })}
                        </Typography>
                      ) : (
                        <Typography variant="body2">
                          {t("rfq:quotations:quotationWillSync")}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Box m={1} />
                </>
              ) : null}

              <Grid item>
                {activeRfq?.status === "Active" && !hasViewerRole ? (
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    color={"primary"}
                    onClick={() => setIsConfirmationDialogOpen(true)}
                  >
                    {t("workspace:chooseThisManufacturer")}
                  </Button>
                ) : null}
                <ConfirmationDialog
                  title={t("rfq:quotations:supplierSelectionModal:title")}
                  //@ts-ignore
                  content={
                    <Box display="flex" flexDirection="column" gap={"24px"}>
                      {t("rfq:quotations:supplierSelectionModal:content", {
                        supplierName: quote.company_info.company_name,
                      })}
                      {isInforErp && (
                        <SyncQuotesToggle syncQuote={syncContract} setSyncQuote={setSyncContract} />
                      )}
                    </Box>
                  }
                  isOpen={isConfirmationDialogOpen}
                  handleClose={handleCloseDialog}
                  dialogActions={
                    <Box pr={2} pb={2} gap={"12px"} display="flex">
                      <Button variant="contained" autoFocus onClick={handleCloseDialog}>
                        {t("rfq:quotations:supplierSelectionModal:cancelText")}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => selectManufacturer(syncContract)}
                        startIcon={<GavelOutlinedIcon />}
                      >
                        {t("rfq:quotations:supplierSelectionModal:okText")}
                      </Button>
                    </Box>
                  }
                />
                {showPDF ? (
                  <div style={{ display: "none" }}>
                    <QuotationPDFViewer
                      ref={pdfComponentRef}
                      quoteIndex={quoteIndex}
                      title={activeRfq.rfq_display_name}
                      quotations={activeRfq.quotations}
                    />
                  </div>
                ) : null}
              </Grid>
            </Grid>

            {/* {erpConfiguration?.erp_type?.value === ErpConfigurationType.INFOR &&
            erpConfiguration?.erp_type?.last_synchronization_date ? (
              <Grid item>
                <Box mr="12px" mt="24px" display="flex" alignItems="center">
                  <UpdateRoundedIcon
                    style={{ color: AppTheme.palette.text.secondary }}
                  />

                  <Box ml="8px">
                    <Typography color="textSecondary" variant="body2">
                      {t("rfq:quotations:synchronisedWithSytelineTodayAt", {
                        date:
                          erpConfiguration?.erp_type
                            ?.last_synchronization_date || "",
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ) : null} */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
