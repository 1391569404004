import React from "react";
import { frontendUrl } from "urls";
import AppLayout from "layout/AppLayout";
import { CustomRoute } from "./types";

const Projects = React.lazy(() =>
  import("@next/modules/project/pages/projects").then((module) => ({
    default: module.Projects,
  }))
);

const ProjectNotFound = React.lazy(() =>
  import("@next/modules/project/pages/project-not-found").then((module) => ({
    default: module.ProjectNotFound,
  }))
);

const ProjectMain = React.lazy(() =>
  import("@next/modules/project/pages/project-main").then((module) => ({
    default: module.ProjectMain,
  }))
);

const PROJECT_ROUTES: CustomRoute[] = [
  {
    // /projects
    layout: AppLayout,
    path: `${frontendUrl.projects}`,
    key: "pm-projects",
    exact: true,
    component: () => <Projects />,
    allowedRoles: ["admin", "normal", "restricted_viewer"],
  },
  {
    // /projects/not-found
    layout: AppLayout,
    path: `${frontendUrl.projects}/not-found`,
    key: "pm-not-found",
    exact: true,
    component: () => <ProjectNotFound />,
  },
  {
    // /projects/<project_code> (Buyer)
    layout: AppLayout,
    path: `${frontendUrl.projects}/:code`,
    key: "pm-details",
    exact: false,
    component: () => <ProjectMain />,
    allowedRoles: ["admin", "normal", "restricted_viewer"],
    customLayoutPadding: "0px",
  },
];

export default PROJECT_ROUTES;
