import React from "react";
import AppLayout from "layout/AppLayout";
import { CustomRoute } from "./types";

const CreateParserConfiguration = React.lazy(
  () => import("@next/modules/ai/pages/create-parsers-configuration")
);

const ListParsersConfiguration = React.lazy(
  () => import("@next/modules/ai/pages/list-parsers-configuration")
);

const ParserConfigurationSetup = React.lazy(
  () => import("@next/modules/ai/pages/parser-configuration-setup")
);

const ParsersConfigurationDetails = React.lazy(
  () => import("@next/modules/ai/pages/list-parsers-configuration-details")
);

const ParserFieldConfiguration = React.lazy(
  () => import("@next/modules/ai/pages/parser-field-config")
);

const AI_ROUTES: CustomRoute[] = [
  {
    layout: (props) => <AppLayout {...props} />,
    path: `/ai/parsers/new`,
    key: "axya-ai-parser-new",
    exact: true,
    component: () => <CreateParserConfiguration />,
  },
  {
    layout: (props) => <AppLayout {...props} />,
    path: `/ai/parsers`,
    key: "axya-ai-list-parsers",
    exact: true,
    component: () => <ListParsersConfiguration />,
  },
  {
    layout: (props) => <AppLayout {...props} />,
    path: `/ai/parsers/:id`,
    key: "axya-ai-parser-config-details",
    exact: true,
    component: () => <ParsersConfigurationDetails />,
  },
  {
    layout: (props) => <AppLayout {...props} />,
    path: `/ai/parsers/:parserId/samples/:sampleId`,
    key: "axya-ai-list-parsers",
    exact: true,
    component: () => <ParserConfigurationSetup />,
  },
  {
    layout: (props) => <AppLayout {...props} />,
    path: `/ai/parsers/:parserId/fields`,
    key: "axya-ai-parser-fields-config",
    exact: true,
    component: () => <ParserFieldConfiguration />,
  },
];

export default AI_ROUTES;
