import React from "react";
import { withTranslation } from "react-i18next";
import { frontendUrl } from "urls";
import AppLayout from "layout/AppLayout";
import { CustomRoute } from "./types";

const CompanyRegistrationContainer = React.lazy(() =>
  import("components/company/registration-page/registration.container").then((module) => ({
    default: module.CompanyRegistrationContainer,
  }))
);

const CompanyProfile = React.lazy(() =>
  import("components/user-company/user-company.container").then((module) => ({
    default: module.default,
  }))
);

const InviteMemberForm = React.lazy(() =>
  import("profiles/companyProfile/components/InviteMemberForm").then((module) => ({
    default: module.default,
  }))
);

const COMPANY_ROUTES: CustomRoute[] = [
  {
    // /company/register
    layout: (props: any) => {
      return <AppLayout {...props} isHideNav={true} />;
    },
    path: frontendUrl.joinOrRegisterCompany,
    key: "companyRegistrationContainer",
    exact: false,
    component: withTranslation()((props) => <CompanyRegistrationContainer {...props} />),
  },
  {
    // /company
    layout: AppLayout,
    path: frontendUrl.companyProfile,
    key: "company",
    exact: true,
    component: withTranslation()((props) => <CompanyProfile {...props} />),
    allowedRoles: ["admin", "normal"],
  },
  // {
  //   // /company/create
  //   layout: AppLayout,
  //   path: "/company/createOld",
  //   key: "createCompany",
  //   exact: true,
  //   component: (props: any) => <CreateCompanyForm {...props} />,
  // },

  {
    // /company/send-invite
    layout: AppLayout,
    path: frontendUrl.inviteMember,
    key: "inviteMember",
    exact: true,
    component: (props: any) => <InviteMemberForm {...props} />,
  },
];

export default COMPANY_ROUTES;
