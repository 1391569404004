import React from "react";
import ValidationPage from "../ValidationPage";
import { frontendUrl } from "../../urls";
import accessDeniedImg from "../../assets/img/access-denied.png";

type Props = {
  t: (key: string) => string;
};

function NotAuthorizedPage(props: Props) {
  return (
    <ValidationPage
      title={props.t("error401Title")}
      body={props.t("error401Body")}
      primaryText={props.t("SIDE_BAR:logout")}
      primaryUrl={frontendUrl.login}
      image={accessDeniedImg}
    />
  );
}

export default NotAuthorizedPage;
