import React, { useEffect, useState } from "react";
import { useTracking } from "react-tracking";
import { connect } from "react-redux";
import { WithTranslation, useTranslation, withTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { workspaceActions } from "../../services/workspace/workspace.actions";
import CloseIcon from "@mui/icons-material/Close";
import AddPart from "../add-part/add-part.container";
import FileUpload from "./upload/upload.component";
import PropTypes from "prop-types";
import { Dialog, DialogContent } from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { modalsActions } from "../../services/modals/modals.actions";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import CreatePartTour from "../tour-guide/create-part-tour/create-part-tour.component";
import uploadSvg from "../../assets/img/Upload.svg";
import { withStyles } from "@mui/styles";
import { AttachmentItem } from "./attachments/attachments.component";

const DialogTitle = withStyles((theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

PartDialog.propTypes = {
  /**
   * A boolean to open/close the dialog
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * A boolean to indicate whether the dialog should load part data
   */
  isEditMode: PropTypes.bool,
  /**
   * A callback executed when a the dialog is closed
   */
  handleCloseModal: PropTypes.func,
  /**
   * A list of attachments that we can pass to the dialog directly.
   * This usually happens when we are dropping files from outside
   * the dialog e.g from the empty workspace upload area.
   */
  attachments: PropTypes.array,
};

type Props = {
  isOpen: boolean;
  handleCloseModal: () => void;
  attachments: any[];
  isEditMode: boolean;
  isPurchaseOrder: boolean;
  contractPk: number;
  partDetails: any;
  isPartDetailsLoading: boolean;
  fileUploadProgress: any[];
  isRfqPartEdit: boolean;
  rfqPartDetails: any;
  isCreatePartTourOpen: boolean;
  handleOpenModal: (isOpen: boolean, modalName: string) => void;
  handleDeleteFileUploadProgress: (file: any) => void;
  profile: any;
  isProject: boolean;
};

function PartDialog({
  isOpen,
  handleCloseModal = () => {},
  attachments = [],
  isEditMode,
  isPurchaseOrder,
  contractPk,
  partDetails,
  isPartDetailsLoading,
  fileUploadProgress,
  isRfqPartEdit = false,
  rfqPartDetails = {},
  isCreatePartTourOpen,
  handleOpenModal,
  handleDeleteFileUploadProgress,
  profile,
  isProject,
  ...props
}: Props & WithTranslation) {
  const { t } = useTranslation("workspace");
  const tracking = useTracking();

  /**
   * states
   */
  const [_attachments, _setAttachments] = useState([]);
  const [isShowPartForm, setIsShowPartForm] = useState(false);
  const [isUploadingInProgress, setIsUploadingInProgress] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [modalWidth, setModalWidth] = useState("");
  const [isDropZone, setIsDropZone] = useState(false);
  const partInformation = isRfqPartEdit ? rfqPartDetails : partDetails;
  /**
   * Effects
   */
  useEffect(() => {
    if (attachments.length > 0) {
      _setAttachments(attachments);
      setIsShowPartForm(true);
    }
  }, [attachments]);

  useEffect(() => {
    if (fileUploadProgress?.length && fileUploadProgress?.length > 0) {
      const updatedFileProgress = filesToUpload.map((file) => {
        const fileProgress = fileUploadProgress.find((item) => file.file_name === item.fileName);
        return { ...file, file_uploaded: fileProgress?.percent };
      });
      setFilesToUpload(updatedFileProgress);
    }
  }, [fileUploadProgress]);

  useEffect(() => {
    if (isEditMode) {
      setIsShowPartForm(true);
    }
  }, [isEditMode]);

  const onUploadDone = () => {
    setIsShowPartForm(true);
  };

  const deleteFile = (fileInfo) => {
    const fileName = fileInfo.file_name;
    const updatedFilesArray = filesToUpload.filter(
      (uploadedFiles) => uploadedFiles.file_name !== fileName
    );
    // cancle request and delete file progress
    handleDeleteFileUploadProgress({
      // @ts-ignore
      filesName: fileInfo.file_name,
    });
    setFilesToUpload(updatedFilesArray);
  };
  // set modal width to small only for purchase order modal,
  // large for add part and edit part modals
  useEffect(() => {
    if (isPurchaseOrder) {
      setModalWidth("sm");
    } else {
      if (modalWidth !== "sm") {
        if (isShowPartForm) {
          setModalWidth("lg");
          setIsDropZone(false);
        } else {
          setIsDropZone(true);
          setModalWidth("md");
        }
      }
    }
  }, [isPurchaseOrder, modalWidth, isShowPartForm]);

  useEffect(() => {
    const isTourOpen = localStorage.getItem("isNewPartVisited");
    if (
      _attachments.length === 1 &&
      profile.user.is_getting_started &&
      !isTourOpen &&
      !isEditMode &&
      isCreatePartTourOpen
    ) {
      handleOpenTour();
      localStorage.setItem("isNewPartVisited", true.toString());
    }
  }, [_attachments]);

  const handleOpenTour = () => {
    handleOpenModal(true, "isCreatePartTourOpen");
    // tracking open create part tour click
    tracking.trackEvent({
      scout_category: "tour_guide",
      scout_feature_name: "open_create_part_tour",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  return (
    <Dialog
      autoDetectWindowHeight={true}
      autoScrollBodyContent={true}
      open={isOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      aria-labelledby="customized-dialog-title"
      fullWidth={true}
      // @ts-ignore
      maxWidth={modalWidth}
      className={!isPurchaseOrder ? "c-create-part-dailog" : "c-upload-po-dialog"}
    >
      <DialogTitle
        id="customized-dialog-title"
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        onClose={handleCloseModal}
      >
        <div className="c-part-dialog">
          {!isEditMode
            ? isPurchaseOrder
              ? t("partDialog:addPurchaseOrder")
              : t("partDialog:newPart")
            : isRfqPartEdit
              ? t("partDialog:editYourRfqsPart")
              : t("partDialog:editYourWorkspacePart")}
          {isEditMode && (
            <Tooltip
              title={
                isRfqPartEdit ? t("partDialog:editRfqPartTooltip") : t("partDialog:notModifyAction")
              }
            >
              <Avatar className="c-part-dialog--avatar">i</Avatar>
            </Tooltip>
          )}
          {!isEditMode && isCreatePartTourOpen && _attachments.length > 0 && (
            <button className="c-part-dialog--tour-btn c-tour-btn" onClick={() => handleOpenTour()}>
              {t("createPart:tourButton")}
            </button>
          )}
          {isCreatePartTourOpen && <CreatePartTour />}
        </div>
      </DialogTitle>
      <DialogContent className={isDropZone ? "c-part-dialog-drop-zone" : "c-part-dialog-content"}>
        {isPartDetailsLoading ? (
          <Box
            style={{ width: "20%", margin: "40% 40% 40% 40%" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress
              // @ts-ignore
              color="grey"
            />
          </Box>
        ) : (
          <Grid
            container
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
            className="c-part-dialog--upload-area"
          >
            {isUploadingInProgress ? (
              <Grid
                container
                xs={12}
                style={{
                  justifyContent: "start",
                  alignItems: "start",
                  flex: 1,
                }}
              >
                <div style={{ width: "100%" }}>
                  {filesToUpload.length &&
                    filesToUpload.map((fileInfo) => (
                      <AttachmentItem
                        selectedAttachment={{ pk: -1 }}
                        listStyleClasses={"list-style-none"}
                        attachmentInfo={fileInfo}
                        isPurchaseOrder={isPurchaseOrder}
                        onAttachmentRemove={deleteFile}
                      />
                    ))}
                </div>
              </Grid>
            ) : isShowPartForm ? (
              <AddPart
                {...props}
                partDetails={partInformation}
                attachments={_attachments}
                isEditMode={isEditMode}
                isPurchaseOrder={isPurchaseOrder}
                // @ts-ignore
                contractPk={contractPk}
                handleClose={() => {
                  // clean up
                  _setAttachments([]);
                  setIsShowPartForm(false);
                  handleCloseModal();
                }}
                isRfqPartEdit={isRfqPartEdit}
                isProject={isProject}
              />
            ) : (
              <Grid container className="part-dialog-drop-zone-container">
                <Grid item xs={12} className="part-dialog-drop-zone-title">
                  {isPurchaseOrder ? (
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: "block",
                          height: 165,
                        }}
                      >
                        <img src={uploadSvg} alt="upload svg icon" width="162" />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item xs={5}>
                        <Box className="box-tile-width">
                          <Box
                            fontSize={20}
                            color="#000000DE"
                            letterSpacing={0.15}
                            textAlign="center"
                            fontWeight="fontWeightMedium"
                          >
                            {t("partDialog:dropZoneTitle1")}
                          </Box>
                          <Box textAlign="center" fontSize={14} color="#00000099" mt={2}>
                            {t("partDialog:dropZoneText1")}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <Box className="box-tile-width">
                          <Box
                            fontSize={20}
                            color="#000000DE"
                            letterSpacing={0.15}
                            textAlign="center"
                            fontWeight="fontWeightMedium"
                          >
                            {t("partDialog:dropZoneTitle2")}
                          </Box>
                          <Box textAlign="center" fontSize={14} color="#00000099" mt={2}>
                            {t("partDialog:dropZoneText2")}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <FileUpload
                  {...props}
                  setUploads={_setAttachments}
                  setFilesToUpload={setFilesToUpload}
                  onUploadDone={onUploadDone}
                  setIsUploadingInProgress={setIsUploadingInProgress}
                  isPurchaseOrder={isPurchaseOrder}
                  supportedExtensions={[
                    "PDF",
                    "JPG",
                    "PNG",
                    "ZIP",
                    "STL",
                    "SLDPRT",
                    "STP",
                    "STEP",
                    "TL",
                    "OBJ",
                    "FBX",
                    "COLLADA",
                    "3DS",
                    "IGES",
                    "IPT",
                    "IGE",
                    "F3D",
                    "DXF",
                    "SLDASM",
                  ]}
                />
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    uploadPart: workspaceActions.uploadPart,
    partDetails: state.workspace.partDetails,
    isPartDetailsLoading: state.workspace.isPartDetailsLoading,
    isCreatePartTourOpen: state.modals.isAddPartModalOpen,
    fileUploadProgress: state.workspaceNext.fileUploadProgress,
  };
};

const mapDispatchToProps = {
  handleOpenModal: modalsActions.showModal,
  handleDeleteFileUploadProgress: workspaceNextActions.removeFileUploadProgress,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PartDialog));
