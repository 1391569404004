import React, { FC } from "react";

import { useDispatch, useSelector } from "react-redux";
// Material ui component and icons
import { Button, Grid } from "@mui/material";

import { frontendUrl } from "urls";

import PartAttachments from "components/part-dialog/attachments/attachments.component";

//helper function
import { history } from "helpers/history";

import { workspaceActions } from "services/workspace/workspace.actions";
import { getToken } from "services/profile/profile.selectors";

import { projectActions } from "@next/modules/project/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import { selectProjectStats } from "@next/modules/project/redux/selectors";
import { SharedModalTypes } from "@next/modals/types";

type Props = {
  isProject?: boolean;
  attachments: any[];
  contractPk?: number;
  isWinnerValidationPage?: boolean;
  uploadSvg: string;
  onUploadSelectionChange: (attachment: any, attachments: any[]) => void;
  isPurchaseOrder?: boolean;
  t: (key: string) => string;
};

export const AddPurchaseOrder: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const projectStats = useSelector(selectProjectStats);

  const handleSubmitPurchaseOrder = () => {
    const poFilePk = props.attachments[0]["pk"];

    if (props.isProject) {
      dispatch(
        projectActions.attachPurchaseOrderRequest({
          contractPk: props?.contractPk,
          poFilePk,
        })
      );
    } else {
      dispatch(
        workspaceActions.patchPurchaseOrder(
          token,
          {
            po_file_pk: poFilePk,
          },
          props?.contractPk,
          projectStats?.code
        )
      );
    }
    dispatch(modalsActions.closeModal(SharedModalTypes.ORDER_PURCHASE_MODAL));
    dispatch(modalsActions.closeModal(ProjectModalTypes.RFQ_DETAILS_MODAL));
    dispatch(workspaceActions.setCongratulationViewDetails({}));

    props.isWinnerValidationPage && !props.isProject && history.push(frontendUrl.buyerContracts);
  };
  return (
    <React.Fragment>
      <Grid item xs={12} className="c-add-more-po-files">
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            className={
              props.attachments.length === 0
                ? "c-add-more-po-files--uploadSvg"
                : "c-add-more-po-files--attachments"
            }
          >
            {props.attachments.length === 0 && (
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                  <img src={props.uploadSvg} alt="" width="162" />
                </Grid>
              </Grid>
            )}
            <PartAttachments
              {...props}
              attachments={props.attachments}
              onAttachmentSelectionChange={props.onUploadSelectionChange}
              isPurchaseOrder={props.isPurchaseOrder}
              height={"50px"}
              scoutCategory="attachments"
              scoutFeatureName="select_PO_attachment"
              scoutDeleteFeatureName="delete_PO_attachment"
            />
          </Grid>
          <Grid item xs={12} className="c-add-more-po-files--btn">
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
              <Grid item xs={3}>
                {props.attachments.length > 0 && (
                  <Button
                    size="large"
                    color={"primary"}
                    variant="contained"
                    onClick={handleSubmitPurchaseOrder}
                  >
                    {props.t("save")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddPurchaseOrder;
