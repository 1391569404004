import AppLayout from "layout/AppLayout";
import AuthLayout from "layout/AuthLayout";
import React from "react";
import { frontendUrl } from "urls";
import { CustomRoute } from "./types";

const OrdersPage = React.lazy(() =>
  import("@next/modules/orders/pages/orders-page").then((module) => ({
    default: module.OrdersPage,
  }))
);

const OrderDetailPage = React.lazy(() =>
  import("@next/modules/orders/pages/order-detail-page/order-detail-page").then((module) => ({
    default: module.OrderDetailPage,
  }))
);

const OrderDetailPortalPage = React.lazy(() =>
  import("@next/modules/orders/pages/order-detail-portal-page/order-detail-portal-page").then(
    (module) => ({
      default: module.OrderDetailPortalPage,
    })
  )
);

const ORDERS_ROUTES: CustomRoute[] = [
  {
    // /orders
    layout: AppLayout,
    path: frontendUrl.orders,
    key: "orders",
    exact: true,
    component: () => <OrdersPage />,
  },
  {
    // /orders/:id
    layout: AppLayout,
    path: `${frontendUrl.orders}/:pk`,
    key: "orderDetail",
    exact: true,
    component: () => <OrderDetailPage />,
  },
  {
    layout: AuthLayout,
    showAxyaLogo: true,
    path: `${frontendUrl.portal}/orders/:orderToken/:qaToken`,
    key: "portal",
    exact: true,
    component: (props: any) => <OrderDetailPortalPage {...props} />,
    allowAuthenticated: true,
  },
];

export default ORDERS_ROUTES;
