import { Chip, Tooltip } from "@mui/material";
import { RFQStatus } from "@next/modules/project/redux";
import { t } from "assets/configi18n/i18n";
import React from "react";
import {
  RfqState,
  StateInfo,
  getStateTagIcon,
  getStateTagStyle,
  getStateTagText,
  getStatusTagIcon,
  getStatusTagStyle,
  getStatusTagText,
} from "./state-status-tag.utils";

type Props = {
  cancellationReason?: {
    reason: string;
    reason_short: string;
  };
  state?: string;
  stateInfo?: string;
  status: string;
  quotationsCount?: number;
  showStateInfoInTag?: boolean;
  onHoldNote?: string;
};

const StateStatusTag: React.FC<Props> = ({
  cancellationReason,
  state,
  stateInfo,
  status,
  quotationsCount,
  showStateInfoInTag,
  onHoldNote,
}) => {
  return (
    <Tooltip
      title={
        cancellationReason?.reason ||
        (stateInfo ? t(`tabView:stateInfo:${stateInfo}`, { count: quotationsCount }) : undefined) ||
        onHoldNote ||
        ""
      }
    >
      <Chip
        size="small"
        icon={getStateTagIcon(state) || getStatusTagIcon(status)}
        label={
          cancellationReason?.reason_short ||
          getStateTagText(
            state as RfqState,
            showStateInfoInTag ? (stateInfo as StateInfo) : undefined,
            quotationsCount
          ) ||
          getStatusTagText(
            status,
            showStateInfoInTag && status === RFQStatus.ON_HOLD ? onHoldNote : undefined
          )
        }
        style={state ? getStateTagStyle(state) : getStatusTagStyle(status)}
      />
    </Tooltip>
  );
};

export default StateStatusTag;
