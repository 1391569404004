import AddRoundedIcon from "@mui/icons-material/AddRounded";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { getSupportedNDAFileTypes } from "@next/utils/constantUtils";
import { convertFilesToObjects } from "@next/utils/fileUtils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { marketplaceApi } from "../../../services/marketplace/marketplace.api";
import { workspaceApi } from "../../../services/workspace/workspace.api";

type Props = {
  hideUploadImage?: boolean;
  setFilesToUpload?: any;
  isPurchaseOrder?: boolean;
  isNDA?: boolean;
  isFinalSignedNda?: boolean;
  rfqPk?: number | string;
  supportedExtensions?: string[];
  variant?: string;
  setIsUploadingInProgress: (isUploading: boolean) => void;
  onUploadDone: () => void;
  setUploads: (uploads: any) => void;
};

const FileUpload = function (props: Props) {
  const {
    hideUploadImage,
    setFilesToUpload,
    isPurchaseOrder,
    isNDA = false,
    isFinalSignedNda,
    rfqPk,
    supportedExtensions = [],
    variant = "dropzone", // "contained" or "dropzone", small usually used when the file uplaod is embedded in a list.
  } = props;
  /**
   * States
   */
  const [dropZoneHighlightCls, setDropZoneHighLightCls] = useState("");
  const [isDropZoneElHidden, setIsDropZoneElHidden] = useState(false);
  const [isDropZoneMaskElHidden, setIsDropZoneMaskElHidden] = useState(true);

  const [uploads, setUploads] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation("workspace");

  const token = useSelector((state: RootState) => state.profile.token);
  const companyType = useSelector((state: RootState) => state.profile.company.type);
  const activeRFQId = useSelector((state: RootState) => state.marketplace.activeRfqDetails.pk);

  /**
   * Refs
   */
  const dropZoneEl = useRef<HTMLDivElement>();
  const selectFileBtn = useRef<HTMLInputElement>();
  const dropZoneMaskEl = useRef<HTMLDivElement>();

  // get different types of upload to make API calls
  const getUploadType = () => {
    if (isPurchaseOrder) {
      if (isNDA) {
        if (companyType === "FAB") {
          return "supplierNDAUpload";
        }
        if (isFinalSignedNda) {
          return "buyerFinalSignedNDAUpload";
        }
        return "buyerNDAUpload";
      }
      return "purchaseOrder";
    } else if (!isPurchaseOrder) {
      return "partUpload";
    } else {
      return "";
    }
  };
  useEffect(() => {
    if (uploads.length === 0) {
      return;
    }

    const fetch = async function () {
      props.setIsUploadingInProgress(true);
      const promises = [];

      let uploadType = getUploadType();

      // @FIXME make it generic without many conditions in this component
      // condition to make different API call for PO and NDA upload
      if (setFilesToUpload) {
        setFilesToUpload(convertFilesToObjects(uploads));
      }
      uploads.forEach((file) => {
        switch (uploadType) {
          case "partUpload":
            promises.push(
              new Promise((resolve, reject) => {
                dispatch(
                  workspaceNextActions.uploadSinglePartFileRequest({
                    file: file,
                    onSuccess: resolve,
                    onError: reject,
                  })
                );
              })
            );
            break;
          case "purchaseOrder":
            promises.push(workspaceApi.uploadPurchaseOrder(token, file));
            break;
          case "buyerNDAUpload":
            promises.push(workspaceApi.buyerUploadNDA(token, file));
            break;
          case "supplierNDAUpload":
            promises.push(marketplaceApi.supplierUploadNDA(activeRFQId, token, file));
            break;
          case "buyerFinalSignedNDAUpload":
            promises.push(workspaceApi.buyerSignedNDAUpload(token, file, rfqPk));
            break;
          default:
            break;
        }
      });
      try {
        let data = await Promise.all(promises);
        data.forEach((upload, index) => {
          if (!upload || upload.canceled) {
            // Remove failed fetches
            delete data[index];
          } else {
            upload.file = uploads[index];
          }
        });
        data = data.filter(Boolean); // Removes empty values from the  array
        if (data.length !== 0) {
          // No file got uploaded. User canceled file upload.
          // Since no file was uploaded, don't show parts form
          props.onUploadDone();
        }
        props.setIsUploadingInProgress(false);
        props.setUploads(data);
      } catch (e) {
        props.setIsUploadingInProgress(false);
        console.log(e);
      }
    };

    fetch();
  }, [uploads]);

  function onDragDragDropEventsHandler(e) {
    // Prevent default so dropped file will not be opened by the browser
    e.preventDefault();

    // Remove/add highlight effect in the dropzone
    switch (e.type) {
      case "dragenter": {
        setDropZoneHighLightCls("dropzone-highlight");
        setIsDropZoneElHidden(false);
        break;
      }
      case "dragover": {
        setDropZoneHighLightCls("dropzone-highlight");
        setIsDropZoneMaskElHidden(false);
        break;
      }

      case "dragleave":
      case "drop": {
        setDropZoneHighLightCls("");
        setIsDropZoneMaskElHidden(true);
        if (e.type === "drop") {
          // Process dropped files
          onFilesAdded(e);
        }
        break;
      }
    }
  }

  function onFilesAdded(e) {
    const files = e.target.files || e.dataTransfer.files;
    if (files.length === 0) {
      return;
    }
    setUploads((currUploads) => [...currUploads, ...Array.from(files)]);
  }

  function resetSelectBtn(e) {
    e.target.value = null;
  }

  function openFileBrowser() {
    selectFileBtn.current.click();
  }

  useEffect(() => {
    // On file drag over 'dropzone'
    dropZoneEl.current?.addEventListener("dragover", onDragDragDropEventsHandler, false);

    // On file drag enter 'dropzone'
    dropZoneEl.current?.addEventListener("dragenter", onDragDragDropEventsHandler, false);

    // On file drag leave mask zone overlayed on top of the drop zone
    dropZoneMaskEl.current?.addEventListener("dragleave", onDragDragDropEventsHandler, false);

    // On file dropped in the mask zone
    dropZoneMaskEl.current?.addEventListener("drop", onDragDragDropEventsHandler, false);

    // On file manually selected
    selectFileBtn.current?.addEventListener("change", onFilesAdded, false);

    // If select file clicked reset file input value
    // Fixes a bug where a user cannot upload the same file twice
    // because the input value was not reset and thus change event
    // is not triggered
    selectFileBtn.current?.addEventListener("click", resetSelectBtn, false);
  }, []);
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      className={`drop-zone-wrapper ${
        variant === "contained" || isPurchaseOrder ? "border-none" : "border-solid"
      }`}
    >
      <Grid
        item
        xs={12}
        ref={dropZoneEl}
        className={`drop-zone ${dropZoneHighlightCls}`}
        style={{ display: isDropZoneElHidden ? "none" : "flex" }}
      >
        <div
          onClick={openFileBrowser}
          className={"drop-zone-mask"}
          ref={dropZoneMaskEl}
          style={{ display: isDropZoneMaskElHidden ? "none" : "flex" }}
        >
          <p className="drop-text">{t("workspace:uploadPartFileDropHere")}</p>
        </div>
        <div className="c-container">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className="c-container--dropzone-grid"
            data-tut="reactour__files"
          >
            <Grid item>
              <Grid container direction="row" className="dropzone-content">
                {variant === "contained" ? (
                  <>
                    <Grid item onClick={openFileBrowser} className="upload-icon-ct">
                      <AddRoundedIcon />
                    </Grid>
                    <Grid item className="dropzone-text">
                      <Typography variant="h6">{t("workspace:addMoreUploads")}</Typography>
                      <Typography variant="subtitle1" className="dropzone-subtitle-text">
                        {t("workspace:uploadPartFileDragFilesInstruction") + " "}
                        <a className="file-select" onClick={openFileBrowser}>
                          <input
                            name="files[]"
                            ref={selectFileBtn}
                            className="file-upload-browse-btn"
                            type="file"
                            multiple={isPurchaseOrder ? false : true}
                            accept={isNDA && `${getSupportedNDAFileTypes()}`}
                          />
                          {t("workspace:uploadPartFileBrowseLabel")}
                        </a>
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <Grid container direction="column">
                    <Grid item className={!isPurchaseOrder && "drop-zone-select-file-btn"}>
                      <Box display="flex" alignItems="center">
                        <Box color="#000000DE" letterSpacing={0.15} textAlign="center" mr={1}>
                          {t("workspace:uploadPartFileDragFilesInstruction")}
                        </Box>
                        <Box letterSpacing={0.5}>
                          <a
                            className="file-select"
                            onClick={openFileBrowser}
                            data-tut="reactour__browse"
                          >
                            <input
                              name="files[]"
                              ref={selectFileBtn}
                              className="file-upload-browse-btn"
                              type="file"
                              multiple={isPurchaseOrder ? false : true}
                              accept={isNDA && `${getSupportedNDAFileTypes()}`}
                            />
                            {t("workspace:uploadPartFileBrowseLabel")}
                          </a>
                        </Box>
                      </Box>
                      <Box className="extensions">
                        {t("workspace:uploadPartFileAllowExtension")}
                        {isPurchaseOrder
                          ? isNDA
                            ? getSupportedNDAFileTypes().join(", ")
                            : ["PDF", "ZIP"].join(", ")
                          : supportedExtensions.join(", ")}
                      </Box>
                      <Box display="flex" alignItems="center" mt={1.5} justifyContent="center">
                        <Box className="secure-msg">
                          <LockIcon />
                        </Box>
                        <Box fontSize={12} textAlign="center" color="#00000099">
                          {t("workspace:secureUploadsMsg")}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default FileUpload;
