import React from "react";
// Routes
import { frontendUrl } from "urls";
// Layout
import AppLayout from "layout/AppLayout";

// Types
import { CustomRoute } from "./types";
import ProfileLayout from "layout/ProfileLayout";

const UserProfileSettingsModal = React.lazy(() =>
  import("@next/modules/profile/modals/user-profile-settings-modal").then((module) => ({
    default: module.UserProfileSettingsModal,
  }))
);

const BuyerProfileView = React.lazy(
  () => import("@next/modules/profile/pages/buyer-profile-view/buyer-profile-view")
);

const RequestDetailView = React.lazy(
  () => import("@next/modules/profile/pages/request-detail-view/request-detail-view")
);

const CompanyProfileFull = React.lazy(
  () => import("@next/modules/profile/pages/company-profile-full")
);

const PROFILE_ROUTES: CustomRoute[] = [
  {
    // /profile/edit
    layout: AppLayout,
    path: frontendUrl.editUserProfile,
    key: "editProfile",
    exact: true,
    component: () => <UserProfileSettingsModal initiallyOpen={true} />,
  },
  {
    // /company/buyer/:id
    layout: ProfileLayout,
    path: `${frontendUrl.buyerProfile}/:id`,
    key: "buyerProfileView",
    exact: true,
    component: () => <BuyerProfileView />,
  },
  {
    // /company/buyer/:id
    layout: ProfileLayout,
    path: `${frontendUrl.publicRequest}/:id`,
    key: "requestDetailView",
    exact: true,
    component: () => <RequestDetailView />,
  },
  {
    // /company/:code
    layout: ProfileLayout,
    path: `${frontendUrl.companyProfile}/:name/:id`,
    key: "companyProfileFull",
    exact: true,
    component: () => <CompanyProfileFull />,
  },
];

export default PROFILE_ROUTES;
