import { ThunkStatusToBoolean } from "helpers/status.enhancer";
import { RootState } from "store";

export const selectCompanyProfileAnalytics = (state: RootState) =>
  state.dashboardNext.companyProfileAnalytics;
export const selectCompanyProfileAnalyticsLoading = (state: RootState) =>
  ThunkStatusToBoolean(state.dashboardNext.companyProfileAnalyticsStatus);

export const selectCompanyProfileViews = (state: RootState) =>
  state.dashboardNext.companyProfileViews;
export const selectCompanyProfileViewsLoading = (state: RootState) =>
  ThunkStatusToBoolean(state.dashboardNext.companyProfileViewsStatus);
export const selectCompanyProfileViewsCount = (state: RootState) =>
  state.dashboardNext.companyProfileViewsCount;
export const selectCompanyProfileViewsLastQuery = (state: RootState) =>
  state.dashboardNext.companyProfileViewsLastQuery;

export const selectBuyerProfiles = (state: RootState) => state.dashboardNext.buyerProfiles;
export const selectBuyerProfilesLoading = (state: RootState) =>
  ThunkStatusToBoolean(state.dashboardNext.buyerProfilesStatus);
export const selectBuyerProfilesCount = (state: RootState) =>
  state.dashboardNext.buyerProfilesCount;

export const selectBuyerProfile = (state: RootState) => state.dashboardNext.buyerProfile;
export const selectBuyerProfileLoading = (state: RootState) =>
  ThunkStatusToBoolean(state.dashboardNext.buyerProfileStatus);

export const selectBuyerProfilePart = (state: RootState) => state.dashboardNext.buyerProfilePart;
export const selectBuyerProfilePartLoading = (state: RootState) =>
  ThunkStatusToBoolean(state.dashboardNext.buyerProfilePartStatus);

export const selectSupplierOnboardingStatus = (state: RootState) =>
  state.dashboardNext.supplierOnboardingStatus;
export const selectSupplierOnboardingStatusLoading = (state: RootState) =>
  ThunkStatusToBoolean(state.dashboardNext.supplierOnboardingStatusStatus);

export const dashboardtSelectors = {
  selectCompanyProfileAnalytics,
  selectCompanyProfileAnalyticsLoading,
  selectCompanyProfileViews,
  selectCompanyProfileViewsLoading,
  selectCompanyProfileViewsCount,
  selectCompanyProfileViewsLastQuery,
  selectBuyerProfiles,
  selectBuyerProfilesLoading,
  selectBuyerProfilesCount,
  selectBuyerProfile,
  selectBuyerProfileLoading,
  selectBuyerProfilePart,
  selectBuyerProfilePartLoading,
  selectSupplierOnboardingStatusLoading,
  selectSupplierOnboardingStatus,
};
