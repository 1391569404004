import { put, takeLatest } from "redux-saga/effects";
import { safeCall } from "@next/redux/safeCall";
import { newsfeedService } from "../services/newsfeed-service";
import { newsfeedActions } from "./slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { fetchNewsfeedInput } from "./types";
import { dashboardActions } from "@next/modules/dashboard/redux";
import { OnboardingSteps } from "@next/modules/dashboard/components/seller-dashboard-onboarding-premium/seller-dashboard-onboarding-premium";

function* handleFetchNewsfeedRequest({ payload }: PayloadAction<fetchNewsfeedInput>) {
  const { response, error } = yield safeCall(newsfeedService.fetchNewsfeed, payload);

  if (error) {
    yield put(newsfeedActions.fetchNewsfeedError(error));
  } else {
    yield put(
      newsfeedActions.fetchNewsfeedSuccess({
        results: response?.data?.results,
        count: response?.data?.count,
        next: response?.data?.next,
        page: payload.page,
      })
    );
    yield put(dashboardActions.showPopUpOnboarding(OnboardingSteps.VISITING_NEWSFEED));
  }
}

export function* newsfeedSaga() {
  yield takeLatest(newsfeedActions.fetchNewsfeedRequest, handleFetchNewsfeedRequest);
}
