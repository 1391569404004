import React from "react";
import { withStyles } from "@mui/styles";
import { Menu } from "@mui/material";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

type Props = {
  isMenuOpen: boolean;
  anchorEl: any;
  handleMenuClose: () => void;
  anchorElement: JSX.Element;
  menuItems: JSX.Element[];
  marginTop?: number;
};

export function CustomizedMenus({
  isMenuOpen,
  anchorEl,
  handleMenuClose,
  anchorElement,
  menuItems,
  marginTop = 0,
}: Props) {
  return (
    <React.Fragment>
      {anchorElement}
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            marginTop: marginTop,
          },
        }}
      >
        {menuItems}
      </StyledMenu>
    </React.Fragment>
  );
}
