import React, { Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import NotFoundPage from "components/not-found-page/not-found-page.component";
import { CustomRoute } from "./config/types";
import { useQueryParam } from "@next/hooks/useQuery";
import snackbarUtils from "@next/utils/snackbarUtils";
import PageLoader from "@next/components/page-loader/page-loader";

export const RenderRoutes = ({ routes, props }: { routes: CustomRoute[]; props: any }) => {
  const [errorMessage, setErrorMessage] = useQueryParam("errorMessage");
  const [successMessage, setSuccessMessage] = useQueryParam("successMessage");
  useEffect(() => {
    if (errorMessage) {
      snackbarUtils.error(errorMessage.toString());
      setErrorMessage("");
    } else if (successMessage) {
      snackbarUtils.success(successMessage.toString());
      setSuccessMessage("");
    }
  }, [errorMessage, successMessage]);

  return (
    <Switch>
      {routes.map((route: CustomRoute, i: number) => {
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            {...props}
            render={(routeProps) => {
              return (
                <route.layout {...props} routeConfig={route}>
                  <Suspense fallback={<PageLoader />}>
                    <route.component {...props} {...routeProps} />
                  </Suspense>
                </route.layout>
              );
            }}
          />
        );
      })}
      <Route
        path="*"
        component={withTranslation("ERRORS")((props) => {
          return <NotFoundPage {...props} />;
        })}
      />
    </Switch>
  );
};
