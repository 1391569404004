/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workspaceNextActions } from "../../redux";
import {
  selectInternalChatTopic,
  selectInternalChatTopicLoading,
  selectPortalData,
  selectPortalDataLoading,
  selectQATopic,
  selectQATopicLoading,
} from "../../redux/selectors";

export const usePublicQuestionAnswerData = (token?: string, skipFetch?: boolean) => {
  const question = useSelector(selectQATopic);
  const questionLoading = useSelector(selectQATopicLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token && !skipFetch) {
      dispatch(
        workspaceNextActions.fetchQATopicRequest({
          token,
        })
      );
    }
  }, [token, skipFetch]);

  return {
    question,
    questionLoading,
  };
};

export const usePublicPortalData = (rfqToken?: string) => {
  const portalData = useSelector(selectPortalData);
  const portalDataLoading = useSelector(selectPortalDataLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (rfqToken) {
      dispatch(
        workspaceNextActions.fetchPortalDataRequest({
          rfqToken,
        })
      );
    }
  }, [rfqToken]);

  return {
    portalData,
    portalDataLoading,
  };
};

export const useInternalChatTopic = (rfqId: number, isPortal?: boolean) => {
  const internalChatTopic = useSelector(selectInternalChatTopic);
  const internalChatTopicLoading = useSelector(selectInternalChatTopicLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isPortal) return;
    if (rfqId) {
      dispatch(
        workspaceNextActions.fetchInternalChatTopicRequest({
          rfqId,
        })
      );
    }
  }, [rfqId]);

  return {
    internalChatTopic,
    internalChatTopicLoading,
  };
};
