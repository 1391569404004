import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import FileUpload from "../upload/upload.component";
import ClearIcon from "@mui/icons-material/Clear";
import LinearProgress from "@mui/material/LinearProgress";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectFileUploadProgress } from "@next/modules/workspace/redux/selectors";
import ConfirmationDialog from "../../../@next/components/confirmation-dialog/confirmation-dialog";
import uploadSvg from "../../../assets/img/Upload.svg";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/styles";
import { formatFileSize } from "@next/utils/fileUtils";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { downloadFile } from "helpers/download";
import { CustomTypography } from "@next/components/custom-typography";
import { RootState } from "store";

const pathAddIcon = "M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      display: "inline-flex",
    },
    textContainer: {
      marginRight: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    bottom: {
      color: theme.palette.grey[(theme.palette?.["type"] as string) === "light" ? 200 : 700],
    },
    top: {
      position: "absolute",
      right: 0,
    },
    file_size: {
      marginRight: "5px",
      lineHeight: "unset",
      whiteSpace: "nowrap",
    },
    text: {
      paddingLeft: "16px",
      paddingRight: "5px",
    },
  })
);

type Props = {
  selectedAttachment: any;
  attachmentInfo: any;
  onAttachmentRemove?: (attachment: any) => void;
  onAttachmentSelectionChange?: (attachment: any, attachments?: any[]) => void;
  downloadable?: boolean;
  hideRemoveIcon?: boolean;
  isPurchaseOrder?: boolean;
  scoutFeatureCategory?: any;
  scoutCategory?: any;
  listStyleClasses?: string;
};

export function AttachmentItem({
  hideRemoveIcon,
  attachmentInfo = {},
  selectedAttachment,
  onAttachmentSelectionChange = () => {},
  onAttachmentRemove = () => {},
  isPurchaseOrder,
  scoutFeatureCategory,
  scoutCategory,
  listStyleClasses,
  downloadable,
  ...props
}: Props) {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const { t } = useTranslation("workspace");

  const handleCloseDialog = () => {
    setIsConfirmationDialogOpen(false);
  };
  const classes = useStyles();

  const progressBar = [
    { value: 100, className: classes.bottom },
    { value: attachmentInfo.file_uploaded, className: classes.top },
  ];

  return (
    <div className={`${listStyleClasses}`}>
      <ListItem
        // @ts-ignore
        button
        divider
        selected={selectedAttachment ? selectedAttachment.pk === attachmentInfo.pk : false}
        fullWidth
        key={attachmentInfo.key}
        onClick={() => onAttachmentSelectionChange(attachmentInfo)}
        className={`${isPurchaseOrder && "background-transparent"}`}
      >
        <Box fontWeight="fontWeightRegular">
          <Chip label={attachmentInfo.file_extension.toUpperCase()} />
        </Box>
        <ListItemText
          inset={true}
          className={classes.text}
          primary={
            <CustomTypography className={`${isPurchaseOrder && "color-item"}`} variant="body2">
              {attachmentInfo.file_name}
            </CustomTypography>
          }
        />
        {attachmentInfo.file_size && (
          <Typography variant="caption" className={classes.file_size}>
            {formatFileSize(attachmentInfo.file_size, 2)}
          </Typography>
        )}
        {downloadable && (
          <ArrowDownwardIcon onClick={(e) => downloadFile(e, attachmentInfo.file_url)} />
        )}
        {attachmentInfo.file_uploaded && (
          <Box className={classes.root}>
            <Box className={classes.textContainer}>
              <Typography variant="caption" component="div">
                {`${Math.round(attachmentInfo.file_uploaded)}%`}
              </Typography>
            </Box>

            {progressBar.map((progress) => (
              <CircularProgress
                variant="determinate"
                className={progress.className}
                size={30}
                value={progress === undefined ? 0 : progress.value}
              />
            ))}
          </Box>
        )}
        {!hideRemoveIcon ? (
          <ListItemSecondaryAction className="remove-attachment-icon">
            <ClearIcon onClick={() => setIsConfirmationDialogOpen(true)} />
          </ListItemSecondaryAction>
        ) : (
          <></>
        )}
      </ListItem>
      <ConfirmationDialog
        title={t("deleteThisFile")}
        content={t("thisActionIsIrreversible")}
        isOpen={isConfirmationDialogOpen}
        handleClose={handleCloseDialog}
        dialogActions={
          <div>
            <Button autoFocus onClick={handleCloseDialog} color="primary">
              {t("cancel")}
            </Button>
            <Button
              onClick={() => {
                handleCloseDialog();
                onAttachmentRemove(attachmentInfo);
              }}
              color="primary"
            >
              {t("delete")}
            </Button>
          </div>
        }
      />
    </div>
  );
}

type PartProps = {
  attachments: any[];
  hideUploadZone?: boolean;
  onAttachmentRemove?: (attachment: any, remoteDelete?: any) => void;
  onAttachmentSelectionChange?: (attachment: any, attachments?: any[]) => void;
  downloadable?: boolean;
  isPurchaseOrder?: boolean;
  height?: string;
  isNDA?: boolean;
  isFinalSignedNda?: boolean;
  rfqPk?: number | string;
  scoutCategory?: string;
  scoutFeatureName?: string;
  scoutDeleteFeatureName?: string;
};
function PartAttachments({
  attachments,
  hideUploadZone = false,
  onAttachmentRemove = () => {},
  onAttachmentSelectionChange = () => {},
  isPurchaseOrder,
  height = "450px",
  isNDA = false,
  isFinalSignedNda,
  rfqPk,
  scoutCategory,
  scoutFeatureName,
  scoutDeleteFeatureName,
  downloadable,
  ...props
}: PartProps) {
  const tracking = useTracking();
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => state.profile);
  const fileUploadProgress = useSelector(selectFileUploadProgress);

  /**
   * States
   */
  const [_attachments, _setAttachments] = useState([]);
  const [isUploadingInProgress, setIsUploadingInProgress] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [uploadingProgress, setUploadingProgress] = useState({});
  const [filesToUpload, setFilesToUpload] = useState([]);
  /**
   * Effects
   */
  useEffect(() => {
    if (fileUploadProgress?.length && fileUploadProgress?.length > 0) {
      const updatedFileProgress = filesToUpload.map((file) => {
        const fileProgress = fileUploadProgress.find((item) => file.file_name === item.fileName);
        return { ...file, file_uploaded: fileProgress?.percent };
      });
      setFilesToUpload(updatedFileProgress);
    }
  }, [fileUploadProgress]);

  useEffect(() => {
    _setAttachments(attachments || []);
  }, [attachments]);

  useEffect(() => {
    // Auto select the first item in the list
    const firstItem = _attachments[0];
    setSelectedAttachment(firstItem);
    onAttachmentSelectionChange(firstItem, _attachments);
  }, [_attachments]);

  const _appendAttachments = (newAttachments) => {
    _setAttachments([..._attachments, ...newAttachments]);
  };

  const _onAttachmentRemove = (attachmentInfoToRemove) => {
    const attachment = _attachments.find((a) => a.pk === attachmentInfoToRemove.pk);
    const index = _attachments.indexOf(attachment);
    // Remove the attachment from the list
    _attachments.splice(index, 1);
    _setAttachments([..._attachments]);
    onAttachmentRemove(attachment);
    let previousAttachment;
    if (index - 1 >= 0) {
      previousAttachment = _attachments[index - 1];
      setSelectedAttachment(previousAttachment);
    }
    onAttachmentSelectionChange(previousAttachment, attachments || []);
    tracking.trackEvent({
      scout_category: scoutCategory,
      scout_feature_name: scoutDeleteFeatureName,
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["username"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  const _onAttachmentSelectionChange = (selectedAttachmentInfo) => {
    onAttachmentSelectionChange(selectedAttachmentInfo, attachments || []);
    setSelectedAttachment(selectedAttachmentInfo);
    tracking.trackEvent({
      scout_category: scoutCategory,
      scout_feature_name: scoutFeatureName,
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["username"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  const deleteFile = (attachmentInfoToRemove) => {
    const filterUploadingList = attachments.filter(
      (file) => file.file_name !== attachmentInfoToRemove.file_name
    );
    dispatch(
      workspaceNextActions.removeFileUploadProgress({
        filesName: attachmentInfoToRemove.file_name,
      })
    );
    setFilesToUpload(filterUploadingList);
  };
  return (
    <>
      {/* hide upload image on NDA file upload progress and after uploading files */}
      {isNDA && _attachments && _attachments.length === 0 && !isUploadingInProgress && (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <img src={uploadSvg} alt="" width="162" />
          </Grid>
        </Grid>
      )}
      <List
        component="nav"
        className={isPurchaseOrder ? "po-attachments" : "part-attachments"}
        aria-label="part name"
        style={{
          flex: "1 1 auto",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {_attachments.map((attachmentInfo) => {
          return (
            <AttachmentItem
              hideRemoveIcon={hideUploadZone}
              selectedAttachment={selectedAttachment}
              attachmentInfo={attachmentInfo}
              onAttachmentSelectionChange={_onAttachmentSelectionChange}
              onAttachmentRemove={_onAttachmentRemove}
              isPurchaseOrder={isPurchaseOrder}
              downloadable={downloadable}
            />
          );
        })}
        {isUploadingInProgress && filesToUpload.length && !isPurchaseOrder && (
          <Box>
            {filesToUpload.length &&
              filesToUpload.map((item) => (
                <AttachmentItem
                  selectedAttachment={{ pk: -1 }}
                  attachmentInfo={item}
                  isPurchaseOrder={isPurchaseOrder}
                  onAttachmentRemove={deleteFile}
                />
              ))}
          </Box>
        )}
        {!hideUploadZone && (
          <>
            <ListItem
              // @ts-ignore
              button
              fullWidth
              class="list-style-none"
            >
              {isUploadingInProgress && isPurchaseOrder ? (
                <Box>
                  <CircularProgress
                    // @ts-ignore
                    color="grey"
                  />
                </Box>
              ) : (
                <>
                  {isPurchaseOrder && _attachments.length === 0 && (
                    <FileUpload
                      {...props}
                      hideUploadImage={true}
                      onUploadDone={() => {}}
                      isPurchaseOrder={isPurchaseOrder}
                      setUploads={_appendAttachments}
                      setIsUploadingInProgress={setIsUploadingInProgress}
                      isNDA={isNDA}
                      isFinalSignedNda={isFinalSignedNda}
                      rfqPk={rfqPk}
                    />
                  )}
                </>
              )}
            </ListItem>
          </>
        )}
      </List>

      {!hideUploadZone && (
        <List>
          <ListItem>
            <div className="bottom-push">
              {!isPurchaseOrder && !isUploadingInProgress && (
                <FileUpload
                  {...props}
                  hideUploadImage={true}
                  onUploadDone={() => {
                    dispatch(workspaceNextActions.resetFileUploadProgress());
                    setFilesToUpload([]);
                  }}
                  setUploads={_appendAttachments}
                  setIsUploadingInProgress={setIsUploadingInProgress}
                  setFilesToUpload={setFilesToUpload}
                  variant={"contained"}
                />
              )}
            </div>
            {!isPurchaseOrder && isUploadingInProgress && (
              <Box sx={{ width: "100%", height: "40px" }}>
                <LinearProgress />
              </Box>
            )}
          </ListItem>
        </List>
      )}
    </>
  );
}

export default PartAttachments;
