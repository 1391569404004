import { useCallback, useState } from "react";
import { debounce } from "lodash";
import {
  GridFilterItem,
  GridFilterModel,
  GridLinkOperator,
  GridSortModel,
} from "@mui/x-data-grid-pro-v5";

const useTableFilterAndSort = (
  getSortQueryString: (model: GridSortModel) => string,
  generateFilterQuery?: (items: GridFilterItem[]) => string
) => {
  const [sortQueryString, setSortQueryString] = useState<string>("");
  const [filterQueryString, setFilterQueryString] = useState<string>("");
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    linkOperator: GridLinkOperator.And,
  });

  const handleFilterChange = useCallback(
    debounce((model: GridFilterModel) => {
      if (JSON.stringify(model) !== JSON.stringify(filterModel)) {
        setFilterModel(model);
        if (generateFilterQuery) {
          setFilterQueryString(generateFilterQuery(model.items));
        }
      }
    }, 1000),
    [filterModel, generateFilterQuery]
  );

  const handleSortChange = (model: GridSortModel) => {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
      setSortQueryString(getSortQueryString(model));
    }
  };
  return {
    handleSortChange,
    handleFilterChange,
    sortQueryString,
    sortModel,
    filterModel,
    filterQueryString,
  };
};

export default useTableFilterAndSort;
