import React from "react";
// Routes
import { frontendUrl } from "urls";
import AppLayout from "layout/AppLayout";
import { CustomRoute } from "./types";
import { PublicLayout } from "layout/PublicLayout";

const Dashboard = React.lazy(() =>
  import("@next/modules/dashboard/pages/dashboard").then((module) => ({
    default: module.Dashboard,
  }))
);

const Analytics = React.lazy(() =>
  import("@next/modules/analytics/pages/analytics").then((module) => ({
    default: module.Analytics,
  }))
);

const WorkspaceTabContainer = React.lazy(() =>
  import("components/workspace/workspace-tab.container").then((module) => ({
    default: module.WorkspaceTabContainer,
  }))
);

const MyParts = React.lazy(() =>
  import("@next/modules/workspace/pages/my-parts/my-parts").then((module) => ({
    default: module.MyParts,
  }))
);

const BuyerRfqs = React.lazy(() =>
  import("@next/modules/workspace/pages/buyer-rfqs/buyer-rfqs").then((module) => ({
    default: module.BuyerRfqs,
  }))
);

const OrdersPage = React.lazy(() =>
  import("@next/modules/orders/pages/orders-page").then((module) => ({
    default: module.OrdersPage,
  }))
);

const SupplierLineItemsPage = React.lazy(() =>
  import("@next/modules/orders/pages/supplier-line-items-page").then((module) => ({
    default: module.SupplierLineItemsPage,
  }))
);

const BuyerRfqsView = React.lazy(
  () => import("components/workspace/buyer-rfqs-view/buyer-rfqs-view.container")
);

const SuppliersTabs = React.lazy(() =>
  import("@next/modules/workspace/pages/my-suppliers/suppliers-tabs").then((module) => ({
    default: module.SuppliersTabs,
  }))
);

const PublicRfqOLD = React.lazy(() =>
  import("@next/modules/workspace/pages/public-rfq-OLD/public-rfq-OLD").then((module) => ({
    default: module.PublicRfqOLD,
  }))
);

const PublicRfqAmq = React.lazy(() =>
  import("@next/modules/workspace/pages/public-rfq/public-rfq").then((module) => ({
    default: module.PublicRfqAmq,
  }))
);

const PublicRfqCreationGeneral = React.lazy(
  () => import("@next/modules/workspace/components/public-rfq-landing/public-rfq-landing")
);

const Newsfeed = React.lazy(() =>
  import("@next/modules/newsfeed/pages/newsfeed").then((module) => ({
    default: module.Newsfeed,
  }))
);

const WORKSPACE_ROUTES: CustomRoute[] = [
  {
    // /dashboard
    layout: AppLayout,
    path: frontendUrl.dashboard,
    key: "dashboard",
    exact: true,
    component: () => <Dashboard />,
    allowedRoles: ["admin", "normal"],
  },
  {
    // /analytics
    layout: AppLayout,
    path: frontendUrl.analytics,
    key: "analytics",
    exact: true,
    component: () => <Analytics />,
    allowedRoles: ["admin", "normal"],
    customLayoutPadding: "0px",
  },
  {
    // /myParts
    layout: AppLayout,
    path: frontendUrl.myParts,
    key: "myParts",
    exact: true,
    component: (props: any) => <WorkspaceTabContainer {...props} />,
    allowedRoles: ["admin", "normal"],
  },
  {
    // /catalog
    layout: AppLayout,
    path: frontendUrl.catalog,
    key: "catalog",
    exact: true,
    component: () => <MyParts />,
    allowedRoles: ["admin", "normal"],
  },
  {
    // /workspace/buyer-quotes
    layout: AppLayout,
    path: frontendUrl.buyerQuotes,
    key: "workspaceBuyerQuotes",
    exact: true,
    component: () => <BuyerRfqs />,
    //if allowedRoles is not provided or is [], then route will be accessible to all roles
  },
  // {
  //   // /poc
  //   layout: AppLayout,
  //   path: frontendUrl.poc,
  //   key: "poc",
  //   exact: true,
  //   component: () => <QuoteComparison />,
  //   //if allowedRoles is not provided or is [], then route will be accessible to all roles
  // },
  {
    // /orders
    layout: AppLayout,
    path: frontendUrl.orders,
    key: "workspaceOrders",
    exact: true,
    component: () => <OrdersPage />,
  },
  {
    // /suppliersOrders
    layout: AppLayout,
    path: frontendUrl.supplierLineItems,
    key: "workspaceSupplierLineItems",
    exact: true,
    component: () => <SupplierLineItemsPage />,
  },
  {
    // /workspace/buyer-quotes/view
    layout: AppLayout,
    path: `${frontendUrl.bundleView}/:id`,
    key: "workspaceBuyerQuote",
    exact: true,
    component: (props: any) => <BuyerRfqsView params={props.match.params} {...props} />,
  },
  {
    // /workspace/my-suppliers
    layout: AppLayout,
    path: `${frontendUrl.mySuppliers}`,
    key: "workspaceMySupplier",
    exact: true,
    component: (props: any) => <SuppliersTabs {...props} />,
    allowedRoles: ["admin", "normal"],
  },
  {
    // /workspace/suppliers-directory
    layout: AppLayout,
    path: `${frontendUrl.suppliersDirectory}`,
    key: "workspaceSuppliersDirectory",
    exact: true,
    component: (props: any) => <SuppliersTabs {...props} />,
    allowedRoles: ["admin", "normal"],
  },
  {
    // /public-rfq
    layout: (props) => <PublicLayout {...props} showAppBar={false} />,
    path: `${frontendUrl.publicRfqOLD}`,
    key: "publicRfqOLD",
    exact: true,
    component: () => <PublicRfqOLD />,
  },
  {
    // /public-rfq-amq
    layout: (props) => <PublicLayout {...props} showAppBar={false} />,
    path: `${frontendUrl.publicRfqAmq}`,
    key: "publicRfqAmq",
    exact: true,
    component: () => <PublicRfqAmq />,
  },
  {
    // /public-rfq-general
    layout: (props) => <PublicLayout {...props} showAppBar={true} />,
    path: `${frontendUrl.publicRfqGeneral}`,
    key: "publicRfqGeneral",
    exact: true,
    component: () => <PublicRfqCreationGeneral />,
  },
  {
    layout: AppLayout,
    path: `${frontendUrl.newsfeed}`,
    key: "NEWSFEED",
    exact: true,
    component: (props: any) => <Newsfeed {...props} />,
    allowedRoles: ["admin", "normal"],
  },
];

export default WORKSPACE_ROUTES;
