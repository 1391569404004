import React from "react";
import { frontendUrl } from "urls";
import AppLayout from "layout/AppLayout";
import { CustomRoute } from "./types";

const PayMain = React.lazy(() =>
  import("@next/modules/pay/pages/pay-main").then((module) => ({
    default: module.PayMain,
  }))
);

const PAY_ROUTES: CustomRoute[] = [
  {
    layout: (props) => <AppLayout isHideNav {...props} />,
    path: `${frontendUrl.axyapay}`,
    key: "axya-pay-onboarding",
    exact: true,
    component: () => <PayMain />,
  },
];

export default PAY_ROUTES;
