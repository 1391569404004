import { PaginatedDataState } from "./types";

export const INITIAL_PAGINATED_DATA_STATE: PaginatedDataState<any> = {
  count: 0,
  page: 1,
  isLoading: false,
  error: undefined,
  next: null,
  previous: null,
  results: [],
};
