import { downloadFileNext } from "@next/utils/fileUtils";

export const downloadFile = function (e: any, fileUrl = "", onFinish = () => {}) {
  let downloadLink = "";
  if (fileUrl && fileUrl.length) {
    downloadLink = fileUrl;
  } else {
    downloadLink = e.target.getAttribute("download-link");
  }
  // download multiple files
  const fileUrls = downloadLink.split(",");
  if (fileUrls.length > 0) {
    fileUrls.forEach((fileUrl) => {
      fetch(fileUrl)
        .then((resp) => resp.blob())
        .then((blob) => {
          // This utility will be deleted in the future, i didn't touch to prevent regressions
          downloadFileNext(
            blob,
            decodeURI(fileUrl.split("?")[0].split("/").slice(-1)), // To remove url encoding from file name. It will convert test%20abc.txt to test abc.txt
            undefined,
            onFinish
          );
        });
    });
  }
};
