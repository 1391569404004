import React, { ReactNode, useEffect, useRef, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import AutocompleteOptions from "components/autocomplete-options/autocomplete-options.component";
import { GenericTextField } from "components/generic-text-field/generic-text-field.component";
import cadImage from "assets/img/default-part-img.png";
import { useSelector } from "react-redux";
import { getPartDefaults } from "services/part-configuration/part-configuration.selectors";
import { Field, Form, Formik, FormikProps } from "formik";
import * as yup from "yup";
import { Button, FormHelperText, Grid } from "@mui/material";
import { frontendUrl } from "urls";
import { selectProjectId } from "@next/modules/project/redux/selectors";

type Props = {
  thumbSrc: string;
  partDetails: any;
  isEditMode: boolean;
  onPartSubmitSuccess: () => void;
  t: any;
  isRfqPartEdit: boolean;
  materials: any;
  processes: any;
  treatments: any;
  attachments: any;
  thumbFilePk: any;
  hasAttachment: any;
  token: any;
  createPart: any;
  editPart: any;
  onSuccess: any;
};

function PartForm({
  thumbSrc,
  partDetails = {},
  isEditMode,
  onPartSubmitSuccess = () => {},
  t,
  isRfqPartEdit,
  ...props
}: Props) {
  const location = useLocation();
  const partDefaults = useSelector(getPartDefaults);
  const projectId = useSelector(selectProjectId);

  const match = matchPath(location.pathname, {
    path: `${frontendUrl.projects}/:code/`,
  });

  const materialData = [];
  props.materials.map((material) => {
    material.alloys.forEach((alloy) => {
      alloy.type = material.name;
      materialData.push(alloy);
    });
  });

  const [_attachments, _setAttachments] = useState(props.attachments);
  const [_defaultFilePreviewIndex, _setDefaultFilePreviewIndex] = useState(props.thumbFilePk);

  const formikRef = useRef<FormikProps<any>>();
  let theAlloy = materialData.find((a) => a.id === partDetails.alloy_pk);
  let theTreatment =
    partDetails.treatment && props.treatments.find((t) => t.pk === partDetails.treatment_pk);

  useEffect(() => {
    if (formikRef.current && props.thumbFilePk) {
      formikRef.current.setFieldValue("files", props.hasAttachment);
    }
  }, [props.hasAttachment]);

  useEffect(() => {
    if (props.thumbFilePk) {
      _setDefaultFilePreviewIndex(props.thumbFilePk);
    }
  }, [props.thumbFilePk]);

  useEffect(() => {
    _setAttachments(props.attachments);
  }, [props.attachments]);

  /**
   * Validation Schema
   */
  const validationSchema = yup.object({
    name: yup.string().max(255).required(t("thisFieldIsRequired")),
    processes: yup.array().required(t("thisFieldIsRequired")),
    alloy: yup
      .object()
      .shape({
        id: yup.number().required(t("thisFieldIsRequired")),
      })
      .nullable()
      .required(t("thisFieldIsRequired")),
    files: yup.bool().oneOf([true], t("pleaseAddFiles")),
  });

  const initialValuesEditPart = {
    name: partDetails.name || "",
    processes: partDetails.processes || [],
    alloy: theAlloy || null,
    treatment: theTreatment || null,
    quantity: partDetails.quantity ?? "",
    additional_details: partDetails.additional_details || "",
  };

  const initialValuesCreatePart = {
    name: "",
    processes: partDefaults?.process ? [partDefaults.process] : [],
    alloy: partDefaults.material?.alloys?.[0] || null,
    treatment: partDefaults?.treatment?.id
      ? { ...partDefaults.treatment, pk: partDefaults.treatment.id }
      : null,
    additional_details: "",
    quantity: "",
  };

  const handleSubmit = (values) => {
    let newPart = {
      name: values.name,
      additional_details: values.additional_details || "",
      material: values.material,
      processes: values.processes.map((p) => p.id),
      alloy: values.alloy.id,
      default_file_preview: _defaultFilePreviewIndex,
      files: _attachments.map((info) => info.pk),
      treatment: values.treatment ? values.treatment.pk : "",
      quantity: values.quantity || 1,
      project: undefined,
    };
    if (match && projectId) {
      newPart.project = projectId;
    }

    if (isEditMode) {
      // Update the form
      props.editPart(props.token, newPart, partDetails["pk"], t, isRfqPartEdit, props.onSuccess);
      onPartSubmitSuccess();
    } else {
      props.createPart(props.token, newPart, props.onSuccess);
      onPartSubmitSuccess();
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={isEditMode ? initialValuesEditPart : initialValuesCreatePart}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, errors, handleChange, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit} className="part-form">
            <Grid container xs={12} spacing={3}>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item className="thumb-wrapper">
                    <img className="part-thumb" alt="Part" src={thumbSrc || cadImage} height="56" />
                  </Grid>
                  <Grid data-tut="reactour__partName" item className="text-field">
                    <GenericTextField name="name" label={t("nameOfThePart")} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid data-tut="reactour__material" item xs={12}>
                <Field
                  name="alloy"
                  label={t("alloy")}
                  component={AutocompleteOptions}
                  staticsData={materialData}
                />
              </Grid>
              <Grid data-tut="reactour__process" item xs={12}>
                <Field
                  name="processes"
                  component={AutocompleteOptions}
                  label={t("machiningProcess")}
                  staticsData={props.processes}
                  multiple
                  t={t}
                />
              </Grid>
              <Grid data-tut="reactour__treatment" item xs={12}>
                <Field
                  name="treatment"
                  component={AutocompleteOptions}
                  label={t("treatment")}
                  staticsData={props.treatments}
                />
              </Grid>
              <Grid data-tut="reactour__quantity" item xs={12}>
                <GenericTextField
                  type="number"
                  name="quantity"
                  label={t("quantity")}
                  onChange={(e) => {
                    setFieldValue("quantity", Math.max(1, parseInt(e.target.value)));
                  }}
                />
              </Grid>
              <Grid data-tut="reactour__comment" item xs={12}>
                <GenericTextField rows={3} name="additional_details" label={t("optionalComment")} />
                <Field type="checkbox" hidden name="files" onChange={handleChange} />
                <FormHelperText id="additional_details">{t("detailsAboutDesign")}</FormHelperText>
              </Grid>
              <Grid item>
                {!!errors.files ? <div className="error">{errors["files"] as string}</div> : <></>}
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
              >
                <Grid item xs={3}>
                  <Button color={"primary"} variant="contained" type="submit">
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default PartForm;
