import React from "react";
import { frontendUrl } from "urls";
import emailSent from "assets/img/email-sent.svg";
import AuthLayout from "layout/AuthLayout";
// Types
import { CustomRoute } from "./types";
import i18n from "assets/configi18n/i18n";
import { KeycloakAuthCallback } from "@next/modules/app/components/keycloak-auth-callback";
import { EmailConfirmed } from "@next/modules/auth/pages/verification/email-confirmed/email-confirmed";

const LoginForm = React.lazy(() => import("components/login-form"));
const LogoutView = React.lazy(() => import("components/logout/logout.container"));
const RecoverPasswordForm = React.lazy(() => import("components/recover-password-form"));
const ResetPasswordForm = React.lazy(() => import("components/reset-password-form"));
const ValidationPage = React.lazy(() => import("components/ValidationPage"));
const UserRating = React.lazy(() => import("components/user-rating/user-rating.component"));
const RequestDemo = React.lazy(() => import("components/request-demo/request-demo.component"));

const PublicQuestionAnswer = React.lazy(() =>
  import("@next/modules/workspace/pages/public-question-answer/public-question-answer").then(
    (module) => ({
      default: module.PublicQuestionAnswer,
    })
  )
);

const PublicItbItnb = React.lazy(() =>
  import("@next/modules/marketplace/pages/public-itb-itnb/public-itb-itnb").then((module) => ({
    default: module.PublicItbItnb,
  }))
);
const SupplierPortalPage = React.lazy(
  () => import("@next/modules/supplier-portal/pages/supplier-portal.page")
);
const PublicPreferences = React.lazy(
  () => import("@next/modules/profile/pages/public-preferences/public-preferences")
);

const Register = React.lazy(() =>
  import("@next/modules/auth/pages/register/register").then((module) => ({
    default: module.Register,
  }))
);

const Verification = React.lazy(() =>
  import("@next/modules/auth/pages/verification/verification").then((module) => ({
    default: module.Verification,
  }))
);

const LinkExpired = React.lazy(() =>
  import("@next/modules/auth/pages/verification/link-expired.tsx/link-expired").then((module) => ({
    default: module.LinkExpired,
  }))
);

const PortalLogin = React.lazy(() =>
  import("@next/modules/supplier-portal/pages/supplier-portal-login.page").then((module) => ({
    default: module.PortalLogin,
  }))
);

const t = i18n.t.bind(i18n);
const AUTH_ROUTES: CustomRoute[] = [
  {
    // /authNotFoundPage
    layout: AuthLayout,
    path: frontendUrl.auth,
    key: "authRoot",
    exact: true,
    component: (props) => <LoginForm {...props} />,
    lightToolbar: true,
  },
  {
    // /auth/login
    layout: AuthLayout,
    path: frontendUrl.login,
    key: "authLogin",
    exact: true,
    component: (props) => <LoginForm {...props} />,
    lightToolbar: true,
  },
  {
    // /auth/login2FA/:ephemeral_token
    layout: AuthLayout,
    path: `${frontendUrl.login2FA}/:ephemeral_token/`,
    key: "authLogin2FA",
    exact: false,
    component: (props) => <LoginForm {...props} />,
    lightToolbar: true,
  },
  {
    // /auth/login2FA/:ephemeral_token
    layout: AuthLayout,
    path: frontendUrl.unsubscribed,
    key: "authUnsubscribed",
    exact: true,
    component: (props) => <LoginForm {...props} unsubscribed />,
    lightToolbar: true,
  },
  {
    // /auth/logout
    layout: ({ children }: { children: any }) => children, // Dummy layout
    key: "logout",
    path: frontendUrl.logout,
    exact: true,
    component: (props: any) => <LogoutView {...props} />,
  },
  {
    // auth/recover
    layout: AuthLayout,
    path: frontendUrl.authIssues,
    key: "authRecover",
    exact: true,
    component: (props) => <RecoverPasswordForm {...props} />,
    lightToolbar: true,
  },
  {
    // auth/email-sent
    layout: AuthLayout,
    path: frontendUrl.emailSent,
    key: "authEmailSent",
    exact: true,
    component: (props: any) => {
      return (
        <ValidationPage
          title={t("auth:validationPage:confirmationEmailTitle")}
          body={t("auth:validationPage:confirmationEmailBody")}
          image={emailSent}
          primaryText={t("auth:validationPage:confirmationEmailButtonText")}
          primaryUrl={frontendUrl.login}
          {...props}
        />
      );
    },
  },
  {
    // /auth/password-reset/:uid/:token
    layout: AuthLayout,
    path: frontendUrl.actualResetPassword,
    key: "resetPassword",
    exact: true,
    component: (props: any) => <ResetPasswordForm params={props.match.params} {...props} />,
    lightToolbar: true,
  },
  {
    // components/answer-question/answer-question-view"
    layout: AuthLayout,
    path: `${frontendUrl.answerQuestionView}/:token/`,
    key: "answerQuestionView",
    exact: true,
    component: (props: any) => <PublicQuestionAnswer {...props} />,
    allowAuthenticated: true,
  },
  {
    layout: AuthLayout,
    path: `${frontendUrl.portal}/login/`,
    key: "portalLogin",
    exact: true,
    component: (props: any) => <PortalLogin {...props} />,
    allowAuthenticated: true,
    lightToolbar: true,
  },
  {
    layout: AuthLayout,
    path: `${frontendUrl.portal}/:token/`,
    key: "portal",
    exact: true,
    component: (props: any) => <SupplierPortalPage {...props} />,
    allowAuthenticated: true,
  },
  {
    layout: AuthLayout,
    path: `${frontendUrl.publicItbItnb}/:token/`,
    key: "publicItbItnb",
    exact: true,
    component: (props: any) => <PublicItbItnb {...props} />,
    allowAuthenticated: true,
  },
  {
    layout: AuthLayout,
    path: `${frontendUrl.publicPreferences}/:token/`,
    key: "publicPreferences",
    exact: true,
    component: (props: any) => <PublicPreferences {...props} />,
    allowAuthenticated: true,
  },
  {
    // components/user-onboarding/user-onboarding.component"
    layout: AuthLayout,
    path: `${frontendUrl.register}`,
    key: "userOnboarding",
    exact: true,
    // component: (props: any) => <UserOnboarding {...props} />,
    component: (props: any) => <Register {...props} />,
    lightToolbar: true,
  },
  {
    layout: AuthLayout,
    path: `${frontendUrl.validationEmailSent}`,
    key: "validationEmailSentPage",
    exact: true,
    component: (props: any) => {
      return <Verification />;
    },
    lightToolbar: true,
  },
  {
    layout: AuthLayout,
    path: `${frontendUrl.linkExpired}`,
    key: "linkExpiredPage",
    exact: true,
    component: (props: any) => {
      return <LinkExpired />;
    },
    lightToolbar: true,
  },
  {
    // components/user-rating/user-rating.component
    layout: AuthLayout,
    path: `${frontendUrl.userRatingView}/:token/`,
    key: "userRatingView",
    exact: true,
    component: (props: any) => (
      <UserRating ratingToken={props.match.params?.token} isPublic={true} />
    ),
    allowAuthenticated: true,
  },
  {
    // components/request-demo/request-demo.component
    layout: AuthLayout,
    path: frontendUrl.requestDemo,
    key: "requestDemoView",
    exact: true,
    component: (props: any) => <RequestDemo {...props} />,
  },
  {
    layout: AuthLayout,
    path: frontendUrl.keycloakAuthCallback,
    key: "keycloakAuthCallback",
    exact: true,
    component: () => <KeycloakAuthCallback />,
    lightToolbar: true,
  },
  {
    layout: AuthLayout,
    path: `${frontendUrl.emailConfirmed}/:token/`,
    key: "emailConfirmedPage",
    exact: true,
    component: (props: any) => {
      return <EmailConfirmed {...props} />;
    },
    lightToolbar: true,
  },
];

export default AUTH_ROUTES;
