import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// Material ui imports
import { Box, Button, Grid, Menu, MenuItem, Tooltip } from "@mui/material";
import noOffersImg from "assets/img/SendToQuotation.svg";
import { OffersView } from "components/workspace/buyer-rfqs-view/offers-view/offers-view.component";
import { InformationPage } from "components/utils/information-page/information-page.component";
import useStateCallback from "@next/hooks/useStateCallback";
import { useReactToPrint } from "react-to-print";
import { QuotationPDFViewer } from "@next/components/quotation-pdf-viewer";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import { ProjectCreateMultiContractModal } from "@next/modules/project/modals/project-create-multi-contract-modal";
import { useHistory } from "react-router-dom";
import { backendUrl, frontendUrl } from "urls";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { fileDownloadFromUrl } from "@next/utils/downloadUtils";
import { getCompany } from "services/profile/profile.selectors";
import { RequestType } from "@next/modules/workspace/components/rfq-creation-form/rfq-creation.form.definitions";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { getReportsButtonAvailable } from "services/part-configuration/part-configuration.selectors";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { DownloadQuotationsAsZip } from "components/utils/download-all-quotation-as-zip/download-all-quotation-as-zip.component";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { workspaceActions } from "services/workspace";

import { Avatar, Checkbox, Divider, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { ProjectShortlistSupplierModal } from "@next/modules/project/modals/project-shortlist-supplier-modal";
import { selectRfiShortlistLoading } from "services/workspace/workspace.selectors";
import CustomButton from "@next/components/custom-button";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "1.5rem",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    dropdownMenu: {
      minWidth: 420,
    },
    dropdownBtn: {
      justifyContent: "center",
      padding: "4px 1rem",
    },
    truncateText: {
      width: 250,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  })
);

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 300,
    fontSize: 12,
    marginTop: 5,
    textAlign: "center",
  },
}))(Tooltip);

type ShortlistDropdownButtonProps = {
  classes: Record<string, any>;
  isLoading: boolean;
  suppliers: Array<{ id: number; image: string; name: string }>;
  handleSubmitClicked: (winners: number[]) => void;
};

const ShortlistDropdownButton: FC<ShortlistDropdownButtonProps> = ({
  classes,
  isLoading,
  suppliers,
  handleSubmitClicked,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleToggle = (id) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSubmit = () => {
    setAnchorEl(null);
    handleSubmitClicked(selectedItems);
  };

  return (
    <>
      <CustomButton
        variant="contained"
        fullWidth
        loading={isLoading}
        disabled={isLoading}
        size="large"
        color="primary"
        onClick={handleClick}
      >
        {t("rfq:quotations:shortlist:shortlistSuppliers")}
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={classes.dropdownMenu}
        sx={{ "& .MuiPaper-root": { minWidth: 320, marginTop: 1 } }}
      >
        {suppliers.map((item) => (
          <MenuItem key={item.id} onClick={() => handleToggle(item.id)}>
            <Avatar src={item.image} sx={{ marginRight: 2 }} />
            <ListItemText
              primary={<Typography className={classes.truncateText}>{item.name}</Typography>}
            />
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={selectedItems.includes(item.id)}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
          </MenuItem>
        ))}
        <Divider />
        <div className={classes.dropdownBtn}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!selectedItems.length}
            onClick={handleSubmit}
          >
            {t("common:proceed")}
          </Button>
        </div>
      </Menu>
    </>
  );
};

type Props = {
  rfqDetails: any;
  token: string;
  selectWinner: (quoteId: number) => void;
  modalState?: boolean;
  handleCloseModal: () => void;
  handleOpenModal: (quoteId: number) => void;
  expendedPanel?: boolean;
};

export const BuyerRfqsOffers = ({
  rfqDetails,
  token,
  selectWinner,
  modalState,
  handleCloseModal,
  handleOpenModal,
  expendedPanel,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);

  const showSupplierSelectionButton =
    !hasViewerRole && rfqDetails?.quotations?.length > 0 && rfqDetails?.status === "Active";

  const [showPDF, setShowPDF] = useStateCallback(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const company = useSelector(getCompany);
  const reportsButtonAvailable = useSelector(getReportsButtonAvailable);
  const isRfiShortlistLoading = useSelector(selectRfiShortlistLoading);

  const pdfComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => pdfComponentRef.current,
    onAfterPrint: () => setShowPDF(false),
    documentTitle: rfqDetails.rfq_display_name,
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const getSuppliersList = () => {
    return rfqDetails.quotations.map(({ company_info }) => ({
      id: company_info.company_pk,
      image: company_info.company_picture,
      name: company_info.company_name,
    }));
  };

  const openExportMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closeExportMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback((cb) => {
    closeExportMenu();
    if (typeof cb === "function") cb();
  }, []);

  const downloadFile = useCallback((file) => {
    if (file?.file_url) {
      fileDownloadFromUrl(file.file_url, file?.file_name, file?.file_extension);
    }
  }, []);

  const onClickContractMultipleSuppliersButton = () => {
    dispatch(
      modalsActions.showModal({
        key: ProjectModalTypes.CREATE_MULTI_CONTRACT_MODAL,
        data: {
          rfq: rfqDetails,
          onSuccess: () => {
            history.push(frontendUrl.buyerContracts);
          },
        },
      })
    );
  };

  const handleShortlistSubmitClicked = (winners) => {
    dispatch(
      modalsActions.showModal({
        key: ProjectModalTypes.SHORTLIST_SUPPLIER_MODAL,
        data: {
          rfq: rfqDetails,
          winnerIDs: winners,
          onSuccess: async (winnerIDs, winnersNote, losersNote) => {
            dispatch(
              workspaceActions.rfiShortlist(rfqDetails.pk, winnerIDs, winnersNote, losersNote)
            );
          },
        },
      })
    );
  };

  const onClickExportQuoteComparisonSheet = () => {
    dispatch(
      workspaceNextActions.exportQuotesComparisonSheetRequest({
        rfqPk: rfqDetails.pk,
        rfqDisplayName: rfqDetails.rfq_display_name,
      })
    );
  };

  const hasQuotationAttachment = useMemo(() => {
    return rfqDetails.quotations.some(
      (quotation) =>
        Array.isArray(quotation.quotation_uploads_list) &&
        quotation.quotation_uploads_list.length > 0
    );
  }, [rfqDetails.quotations]);

  const isRequestTypeNotRfiAndSimpleRFQ = useMemo(() => {
    return (
      rfqDetails.request_type !== RequestType.RFI &&
      rfqDetails.request_type !== RequestType.SIMPLE_RFQ
    );
  }, [rfqDetails.request_type]);

  const isRequestTypeRfi = useMemo(() => {
    return rfqDetails.request_type === RequestType.RFI;
  }, [rfqDetails.request_type]);

  return (
    <section>
      <Box mt="1.5rem">
        <Grid container spacing={1}>
          {isRequestTypeNotRfiAndSimpleRFQ &&
            (showSupplierSelectionButton ? (
              <Grid item>
                <Button
                  onClick={onClickContractMultipleSuppliersButton}
                  variant="contained"
                  fullWidth
                  size="large"
                  color="primary"
                  startIcon={<GavelOutlinedIcon />}
                >
                  {t("rfq:quotations:supplierSelection")}
                </Button>
              </Grid>
            ) : null)}

          {isRequestTypeNotRfiAndSimpleRFQ &&
            (rfqDetails.quotations.length === 0 ? null : (
              <Grid item>
                <HtmlTooltip
                  title={
                    rfqDetails?.hide_quotation_sensitive_data
                      ? t("rfq:quotations:toolTipExportQuotationsPDFDisabled")
                      : ""
                  }
                >
                  <span>
                    <Button
                      onClick={openExportMenu}
                      variant="outlined"
                      fullWidth
                      size="large"
                      color="secondary"
                      startIcon={<CallMadeIcon />}
                      disabled={rfqDetails?.hide_quotation_sensitive_data}
                    >
                      {t("rfq:quotations:export")}
                    </Button>
                  </span>
                </HtmlTooltip>
              </Grid>
            ))}

          {/* RFIs with quotations and no shortlisted quotations */}
          {isRequestTypeRfi &&
            rfqDetails.quotations.length !== 0 &&
            rfqDetails.shortlisted?.length === 0 && (
              <Grid item>
                <ShortlistDropdownButton
                  isLoading={isRfiShortlistLoading}
                  classes={classes}
                  suppliers={getSuppliersList()}
                  handleSubmitClicked={handleShortlistSubmitClicked}
                />
              </Grid>
            )}
          {hasQuotationAttachment && (
            <Grid item>
              <DownloadQuotationsAsZip
                quotations={rfqDetails.quotations}
                disabled={rfqDetails?.hide_quotation_sensitive_data}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <div className={`root ${classes.root}`}>
        <Grid item xs={12}>
          {/** DISPLAY LIST OF OFFERS */}
          {rfqDetails.quotations.length > 0 ? (
            rfqDetails.quotations.map((quote, ix) => {
              return (
                <OffersView
                  key={ix}
                  quoteIndex={ix}
                  quote={quote}
                  activeRfq={rfqDetails}
                  token={token}
                  selectWinner={selectWinner}
                  t={t}
                  modalState={modalState}
                  handleOpenModal={handleOpenModal}
                  handleCloseModal={handleCloseModal}
                  expendedPanel={expendedPanel}
                />
              );
            })
          ) : (
            <InformationPage
              src={noOffersImg}
              variant="body2"
              text={t("workspace:thereAreNoBidsForNow")}
            />
          )}
        </Grid>
      </div>
      {showPDF ? (
        <div style={{ display: "none" }}>
          <QuotationPDFViewer
            ref={pdfComponentRef}
            title={rfqDetails.rfq_display_name}
            quotations={rfqDetails.quotations}
          />
        </div>
      ) : null}

      <ProjectShortlistSupplierModal />
      <ProjectCreateMultiContractModal />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeExportMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {rfqDetails.quotation_extra_fields ? null : (
          <MenuItem onClick={() => handleMenuItemClick(() => setShowPDF(true, handlePrint))}>
            {t("rfq:quotations:exportQuotationsPDF")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() =>
            handleMenuItemClick(() =>
              fileDownloadFromUrl(
                `${backendUrl.rfqs}/${rfqDetails.pk}/exportConsolidatedQuotationReport`,
                `consolidated_quotation_report_${rfqDetails.pk}`,
                undefined,
                true
              )
            )
          }
        >
          {t("rfq:quotations:exportQuotationsXLSX")}
        </MenuItem>

        {!!company?.requires_consolidated_report ? (
          <Tooltip
            open={!!rfqDetails.consolidated_report ? false : undefined}
            title={
              !rfqDetails.consolidated_report
                ? "The consolidated export will be available after the RFQ end date"
                : null
            }
          >
            <span>
              <MenuItem
                onClick={() => downloadFile(rfqDetails.consolidated_report)}
                disabled={!rfqDetails.consolidated_report}
              >
                {t("rfq:quotations:exportConsolidatedReport")}
              </MenuItem>
            </span>
          </Tooltip>
        ) : null}

        {!!reportsButtonAvailable ? (
          <MenuItem onClick={onClickExportQuoteComparisonSheet}>
            {t("rfq:quotations:exportQuotesComparisonSheet")}
          </MenuItem>
        ) : null}
      </Menu>
    </section>
  );
};
