import React, { ReactNode } from "react";
import { ModalProps, Popover } from "@mui/material";
import { SellerRating } from "./seller-rating/seller-rating.component";

type Props = {
  handleClose: ModalProps["onClose"];
  open?: boolean;
  anchorEl: any;
  sellerData: any;
  popoverPosition: { popoverLeft: number; popoverTop: number };
  ratingScores: any;
};

export const SellerInfoPopover = ({
  handleClose,
  open,
  anchorEl,
  sellerData,
  popoverPosition: { popoverLeft, popoverTop },
  ratingScores,
}: Props) => {
  return (
    <Popover
      id={"mouse-over-popover"}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: popoverTop,
        left: popoverLeft + 570,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleClose}
      disableRestoreFocus
    >
      <SellerRating sellerData={sellerData} ratingScores={ratingScores} />
    </Popover>
  );
};
