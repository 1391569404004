import ValidationPage from "../ValidationPage";
import { frontendUrl } from "../../urls";
import notFound from "../../assets/img/oops-not-found.png";
import { useSelector } from "react-redux";
import { RootState } from "store";

type Props = {
  t: (key: string) => string;
};

function NotFoundPage(props: Props) {
  const profile = useSelector((state: RootState) => state.profile);

  return (
    <ValidationPage
      title={props.t("error404Title")}
      body={props.t("error404Body")}
      primaryText={props.t(profile?.isLoggedIn ? "back_to_main_page" : "error404Redirection")}
      primaryUrl={profile?.isLoggedIn ? frontendUrl.home : frontendUrl.logout}
      image={notFound}
    />
  );
}

export default NotFoundPage;
