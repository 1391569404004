import { configureStore } from "@reduxjs/toolkit";
import { history } from "helpers/history";

// Connected React Router
import { routerMiddleware } from "connected-react-router";
import { initStateWithPrevTab } from "redux-state-sync";

// Thunk
import thunk from "redux-thunk";

// Saga
import createSagaMiddleware from "redux-saga";
import rootSaga from "@next/redux/sagas";

// Persistance
import { createTransform, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Services
import { rootReducer } from "services";
import { initialState } from "@next/modules/project/redux";
import { statusHandlerEnahncer } from "helpers/status.enhancer";

// Configuration for the persistance layer of Redux
const persistConfig = {
  key: "root",
  storage,
  // There is no need to persist modals reducer otherwise we need to clean it on every refresh.
  blacklist: ["modalsNext", "vendorManagementNext"],
  transforms: [
    createTransform(
      (inboundState) => inboundState,
      (outboundState, key) => {
        switch (key) {
          case "projectNext":
            return { ...initialState, ...((outboundState as object) || {}) };
          default:
            return outboundState;
        }
      },
      { whitelist: ["projectNext"] }
    ),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware({
  onError: (err, errorInfo) => {
    console.error("SAGA ERROR", { error: err, info: errorInfo.sagaStack });
  },
});

const middlewares = [thunk, sagaMiddleware, routerMiddleware(history)];

// Create the store using configureStore. We explicitly supply our middleware
// and append the status enhancer along with the default enhancers.
export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => middlewares,
  enhancers: (defaultEnhancers) => [...defaultEnhancers, statusHandlerEnahncer],
});

export const persistor = persistStore(store);

store.subscribe(() => {
  // Sync the state to the localstorage
  localStorage.setItem("accessToken", store.getState()?.profile?.token);
  localStorage.setItem("userId", store.getState()?.profile?.user?.id ?? "");
});

initStateWithPrevTab(persistor as any);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
