//this component is used to show application tour

// general imports
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";

import { useTranslation } from "react-i18next";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { modalsActions } from "../../../services/modals/modals.actions";
import { AppTheme } from "layout/AppTheme";
import { RootState } from "store";

const CreatePartTour = () => {
  const { t } = useTranslation("createPart");
  const tourConfig = [
    {
      selector: '[data-tut="reactour__partName"]',
      content: () => (
        <div>
          <h3>{t("nameOfThePart")}</h3>
          <p>{t("addThePartNumber")}</p>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__material"]',
      content: () => (
        <div>
          <h3>{t("material")}</h3>
          <p>{t("materialDescription")}</p>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__process"]',
      content: () => (
        <div>
          <h3>{t("process")}</h3>
          <p>{t("processDescription")}</p>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__treatment"]',
      content: () => (
        <div>
          <h3>{t("treatment")}</h3>
          <p>{t("treatmentDescription")}</p>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__comment"]',
      content: () => (
        <div>
          <h3>{t("comment")}</h3>
          <p>{t("commentDescription")}</p>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__files"]',
      content: () => (
        <div>
          <h3>{t("files")}</h3>
          <p>{t("filesDescription")}</p>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__snapshot"]',
      content: () => (
        <div>
          <h3>{t("snapshot")}</h3>
          <p>{t("snapshotDescription")}</p>
        </div>
      ),
    },
  ];

  const CloseBtn = () => {
    const dispatch = useDispatch();
    const tracking = useTracking();
    const profile = useSelector((state: RootState) => state.profile);
    const tourDoneClick = () => {
      dispatch(modalsActions.hideModal("isCreatePartTourOpen"));
      // tracking close rfq tour click
      tracking.trackEvent({
        scout_category: "tour_guide",
        scout_feature_name: "tour_done",
        scout_company: profile["company"]["name"],
        scout_username: profile["user"]["name"],
        scout_user_type: profile["user"]["type"],
        scout_date: new Date(),
      });
    };

    return (
      <button className="c-tour--done-btn" onClick={() => tourDoneClick()}>
        {t("gotItButton")}
      </button>
    );
  };

  const fullScreenConfig = {
    selector: '[data-tut="reactour__fullscreen"]',
    content: () => (
      <div>
        <h3>{t("fullScreen")}</h3>
        <p>{t("fullScreenDescription")}</p>
        <CloseBtn />
      </div>
    ),
  };
  const dispatch = useDispatch();
  const tracking = useTracking();
  const profile = useSelector((state: RootState) => state.profile);
  const isCreatePartTourOpen = useSelector((state: RootState) => state.modals.isCreatePartTourOpen);
  const closeTour = () => {
    dispatch(modalsActions.hideModal("isCreatePartTourOpen"));
    // tracking close create part tour click
    tracking.trackEvent({
      scout_category: "tour_guide",
      scout_feature_name: "close_create_part_tour",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const accentColor = AppTheme.palette.primary.main;
  // FIXME: better way to handle this if condition
  let steps = tourConfig;
  const fullScreenElement = document.querySelector(fullScreenConfig.selector);
  if (
    fullScreenElement != null &&
    // @ts-ignore
    fullScreenElement.style.display === "block"
  ) {
    steps = [...tourConfig, fullScreenConfig];
  }
  const trackingApi = () => {
    tracking.trackEvent({
      scout_category: "tour_guide",
      scout_feature_name: "ongoing_create_part_tour",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };
  return (
    <section className="c-app-tour">
      <Tour
        onRequestClose={closeTour}
        steps={steps}
        isOpen={isCreatePartTourOpen}
        maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        getCurrentStep={trackingApi}
      />
    </section>
  );
};

export default CreatePartTour;
