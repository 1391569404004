// React
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchSTEPFileSTLPreview } from "../../../services/common/common.api";
import CSVViewerDataGrid from "./csv-viewer.component";
// Helper
import {
  clearFetchSTEPFileSTLPreviewInterval,
  clearViewer,
  fitToView,
  getBoundingBox,
  getFileExtensionFromSource,
  getProjectionName,
  getSnapshot,
  goFullscreen,
  goToView,
  loadFile,
  loadViewersLibs,
  mmToIn,
  setIsometric,
  setSolid,
  setWired,
  setup3DViewer,
  setupCanvas2D,
  timeOutFetchSTEPFileSTLPreviewInterval,
} from "./viewer.helper";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Fullscreen } from "@mui/icons-material";
import { Box, CircularProgress, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PropTypes from "prop-types";
import { useTracking } from "react-tracking";
import { RootState } from "store";

export const CAD_DISPLAY_MODE = {
  stl: "stl_mode",
  stl_vd: "stl_visibility_detection_mode",
};

Viewer.propTypes = {
  /**
   * A HTML5 file object
   */
  activeFilePk: PropTypes.number.isRequired,
  /**
   * A HTML5 file object
   */
  src: PropTypes.any,

  /**
   * Disable snapshot
   */
  disableSnapshot: PropTypes.bool,
  /**
   * Disable fullscreen
   */
  disableFullscreen: PropTypes.bool,
  /**
   * A callback executed when a snapshot is taken
   */
  onSnapshot: PropTypes.func,
  /**
   * A callback executed when the viewer is ready
   */
  onViewerReady: PropTypes.func,

  /**
   * Apply a background color to the cad viewer
   */
  cadViewerBackgroundColor: PropTypes.string,

  /**
   * The CAD viewer display mode, takes one of the following values
   * `stl` to load the regular STL (by default)
   * `stl_vd_region` to load the visibility detection stl file if available,
   *  if not it will fallback on the STL file.
   */
  cad_display_mode: PropTypes.string,
};

type Props = {
  activeFilePk: number;
  src: any;
  disableSnapshot?: boolean;
  disableFullscreen?: boolean;
  onSnapshot?: () => void;
  onViewerReady?: () => void;
  cad_display_mode?: string;
  cadViewerBackgroundColor?: string;
  isEditMode?: boolean;
};

/**
 * A viewer component composed of 3 type of viewer.
 * - A 3D canvas to display STL files
 * - A 2D canvas to render images and PDF files as images where they can be manipulated
 * - A div to embed the PDF in the browser as a regular PDF reader using the PDFObject library
 *
 * Used external libraries: @see viewer.helper.jsx#loadViewersLibs
 * - xeogl and xelog STLModel for the 3D viewer to render STL files
 * - pdf-js to be able to render the PDF file in a the 2d canvas
 * - fabricjs to manipulate images inside the 2D canvas
 * - pdfobject for the PDF reader
 *
 * Note that when the `disableSnapshot` flag is set the PDF reader is used instead  of the 2D canvas.
 * We usually disable the  snapshot when the viewer is used in a read only mode e.g viewing part files.
 */
function Viewer({
  activeFilePk,
  src,
  disableSnapshot = false,
  disableFullscreen = false,
  isEditMode = false,
  cad_display_mode = CAD_DISPLAY_MODE.stl,
  cadViewerBackgroundColor = null,
  onSnapshot = () => {},
  onViewerReady = () => {},
}: Props) {
  /**
   * Refs
   */

  const canvas3DViewer = useRef();
  const pdfViewer = useRef();
  const canvasWrapper = useRef();
  const viewerGrid = useRef();
  const fullscreenButton = useRef();
  const viewSection = useRef();
  /**
   * Localization support
   */
  const { t } = useTranslation("viewer");
  /**
   * States
   */
  const [viewerReady, setViewerReady] = useState(false);
  const [isManualSnapshot, setIsManualSnapshot] = useState(isEditMode);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [vdRegionFile, setVdRegionFile] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isViewerLoading, setIsViewerLoading] = useState(true);
  const [loadedSTLPreviewFiles, setLoadedSTLPreviewFiles] = useState({});
  const [loadingStatus, setLoadingStatus] = useState(t("loading"));
  const [isCSVViewer, setIsCSVViewer] = useState(false);

  const { token, company, user } = useSelector((state: RootState) => state.profile);

  const tracking = useTracking();

  /**
   * Effects
   */
  useEffect(() => {
    // Load third party libraries
    loadViewersLibs({
      on3DViewerReady: () => {
        setup3DViewer(canvas3DViewer.current);
        setupCanvas2D();
        setViewerReady(true);
        onViewerReady();

        tracking.trackEvent({
          scout_category: "3D viewer",
          scout_feature_name: "on_3D_viewer_ready",
          scout_company: company.name,
          scout_username: user.username,
          scout_user_type: user.type,
          scout_open_full_screen: true,
          scout_date: new Date(),
        });
      },
    });
  }, []);

  useEffect(() => {
    // When the viewer is load in readonly, the `src` variable
    // will contain a string and if it is an upload, the `src` will be
    // a file object.
    if (!src) {
      return;
    }
    // Enable the ciruclar loader
    setIsViewerLoading(true);
    const ext = getFileExtensionFromSource(src);
    const isSTPFile = ["stp", "step"].includes(ext.toLowerCase());
    // Make to always clear the interval function that fetches the STL preview
    // whenver a new file is selected and about to laod.
    clearFetchSTEPFileSTLPreviewInterval();
    if (isSTPFile) {
      // If the file is a step file, try to get the STL preview URL
      // using a periodic fetch since the conversion can take some time.
      const fetchSTLPreview = () => {
        fetchSTEPFileSTLPreview(token, activeFilePk).then((data) => {
          setVdRegionFile(null);
          setFile(null);
          setFileUrl(null);
          if (cad_display_mode === CAD_DISPLAY_MODE.stl_vd && data.stl_visibility_exit_code <= 1) {
            if (data.file_stl) {
              if (data.stl_visibility_exit_code === -1) {
                setLoadingStatus(t("stepCheckingForVisibilityIssues"));
              }
            } else {
              setLoadingStatus(t("stepProcessing"));
            }
            if (data.file_stl_visibility) {
              fileVDFromUrl(data.file_stl_visibility);
            }
            if (data.stl_visibility_exit_code === -1) {
              return;
            }
          }
          if (data.file_stl) {
            setLoadedSTLPreviewFiles({
              ...loadedSTLPreviewFiles,
              [activeFilePk]: data.file_stl,
            });
            fileFromUrl(data.file_stl);
            setFileUrl(data.file_stl);
            clearFetchSTEPFileSTLPreviewInterval();
          } else {
            setLoadingStatus(t("stepProcessing"));
          }
        });

        tracking.trackEvent({
          scout_category: "3D viewer",
          scout_feature_name: "fetch_STL_preview",
          scout_company: company.name,
          scout_username: user.username,
          scout_user_type: user.type,
          scout_open_full_screen: true,
          scout_date: new Date(),
        });
      };

      const STL_PREVIEW_FETCH_INTERVAL = 500;
      const STL_PREVIEW_FETCH_TIMEOUT = 1000 * 60; // Wait for 10 seconds and stop the request

      /**
       * Starts a fetch task to get the STL preview of the STEP file
       */

      // @ts-ignore
      window._fetchSTLPreview = setInterval(fetchSTLPreview, STL_PREVIEW_FETCH_INTERVAL);

      /**
       * Runs a timeout to cancel the fetch interval to avoid
       * having an infinite circular loading if the conversion
       * fails or takes more than 10 seconds.
       */
      // @ts-ignore
      window._fetchSTLPreviewTimeout = setTimeout(
        timeOutFetchSTEPFileSTLPreviewInterval.bind(null, () => {
          if (typeof src === "string") {
            fileFromUrl(src);
            setFileUrl(src);
          } else {
            setFile(src);
          }
        }),
        STL_PREVIEW_FETCH_TIMEOUT
      );
      return;
    }
    const isFileUrl = typeof src === "string";
    const isFileObject = src instanceof File;
    if (isFileUrl) {
      fileFromUrl(src);
      setFileUrl(src);
    } else if (isFileObject) {
      setFile(src);
    } else {
      clearViewer(getActiveViewer());
    }
  }, [src]);

  useEffect(() => {
    if (viewerReady && file) {
      let lastIndex = file.name.lastIndexOf(".");
      let name = file.name.substring(0, lastIndex);
      let fileExtention = file.name.substring(lastIndex + 1);

      fileExtention = fileExtention.split("?")[0].toLowerCase();
      setFileName(`${name}.${fileExtention}`);
      // Silly way but it works! .
      // We could use one viewer with conditional rendering
      // but it will make the state hard to manage because of race conditions.
      // @ts-ignore
      canvas3DViewer.current.style.display = fileExtention === "stl" ? "block" : "none";
      // @ts-ignore
      fullscreenButton.current.style.display = fileExtention === "stl" ? "block" : "none";
      document.getElementById("2d-viewer").style.display = ["stl"].includes(fileExtention)
        ? "none"
        : "block";

      // The fabricjs canvas gets wrapped by a div.
      // Make sure that the div is also hidden if the file
      // requires the 3d viewer or a the pdf object viewer when
      // we are in view mode.
      const canvs2DWrapper = document.querySelector(".canvas-container");
      // @ts-ignore
      canvs2DWrapper.style.display =
        // @ts-ignore
        canvas3DViewer.current.style.display === "block" ? "none" : "block";

      // By default we render the PDF inside a canvas.
      // If we are in the view only mode (snapshot feature is disabled)
      if (disableSnapshot && fileExtention === "pdf") {
        // @ts-ignore
        pdfViewer.current.style.display = "block";
        // @ts-ignore
        canvs2DWrapper.style.display = "none";
      } else {
        // @ts-ignore
        pdfViewer.current.style.display = "none";
      }

      if (fileExtention === "csv") {
        setIsCSVViewer(true);
      } else {
        setIsCSVViewer(false);
      }

      // @ts-ignore
      clearViewer();
      loadFile(getActiveViewer(), {
        file,
        fileUrl,
        ext: fileExtention,
        disableSnapshot,
        // @ts-ignore
        disableFullscreen,
        vdRegionFile,
      }).then(() => {
        if (!isManualSnapshot && !disableSnapshot) {
          takeSnapshot();
        }
        setIsViewerLoading(false);
      });
    }
  }, [viewerReady, file]);

  /**
   * Converts a file URL to a blob
   * @param {*} url
   */
  async function fileFromUrl(url) {
    let response = await fetch(url, {
      credentials: "same-origin",
    });
    let data = await response.blob();
    const [_, fileName, fileExtention] = url.match(/.*\/(.*)\.(.*)\??|$/);
    const file = new File([data], `${fileName}.${fileExtention}`);
    setFile(file);
    tracking.trackEvent({
      scout_category: "3D viewer",
      scout_feature_name: "file_from_url",
      scout_company: company.name,
      scout_username: user.username,
      scout_user_type: user.type,
      scout_open_full_screen: true,
      scout_date: new Date(),
    });
  }

  async function fileVDFromUrl(url) {
    let response = await fetch(url, {
      credentials: "same-origin",
    });
    let data = await response.blob();
    const [_, fileName, fileExtention] = url.match(/.*\/(.*)\.(.*)\??|$/);
    const file = new File([data], `${fileName}.${fileExtention}`);
    setVdRegionFile(file);
    tracking.trackEvent({
      scout_category: "3D viewer",
      scout_feature_name: "file_VDF_from_url",
      scout_company: company.name,
      scout_username: user.username,
      scout_user_type: user.type,
      scout_open_full_screen: true,
      scout_date: new Date(),
    });
  }

  /**
   * A click handler when the snapshot icon is clicked.
   */
  const takeSnapshot = () => {
    getSnapshot(getActiveViewer()).then((b64) => {
      // @ts-ignore
      onSnapshot(b64);
    });
    tracking.trackEvent({
      scout_category: "3D viewer",
      scout_feature_name: "take_snapshot",
      scout_company: company.name,
      scout_username: user.username,
      scout_user_type: user.type,
      scout_open_full_screen: true,
      scout_date: new Date(),
    });
  };

  /**
   * A click handler when the fullscreen icon is clicked.
   */
  const handleFullscreen = () => {
    setIsFullscreen(true);
    // @ts-ignore
    if (Document.fullScreen) {
      setIsFullscreen(false);
      return;
    }
    // @ts-ignore
    canvasWrapper.current.style.height = "100%";
    goFullscreen(viewerGrid.current, viewSection.current);
    // @ts-ignore
    fullscreenButton.current.setAttribute("onClick", "javascript: exitFullscreen();");
    /**
     * Feature tracking
     */
    // const state = store.getState();
    tracking.trackEvent({
      scout_category: "feature",
      scout_feature_name: "file_viewer_fullscreen",
      scout_company: company.name,
      scout_username: user.username,
      scout_user_type: user.type,
      scout_open_full_screen: true,
      scout_date: new Date(),
    });
  };

  /**
   * A click handler when the isometric view button is clicked.
   */
  const handleIsometricView = () => {
    setIsometric();
    fitToView();
    tracking.trackEvent({
      scout_category: "3D viewer",
      scout_feature_name: "handle_isometric_view",
      scout_company: company.name,
      scout_username: user.username,
      scout_user_type: user.type,
      scout_open_full_screen: true,
      scout_date: new Date(),
    });
  };

  /**
   * A click handler when a projection view button is clicked.
   */
  const handleProjectionView = (projection) => {
    goToView(projection);
    tracking.trackEvent({
      scout_category: "3D viewer",
      scout_feature_name: `handle_projection_view-${getProjectionName(projection)}`,
      scout_company: company.name,
      scout_username: user.username,
      scout_user_type: user.type,
      scout_open_full_screen: true,
      scout_date: new Date(),
    });
  };

  /**
   * Gets the current visible viewer
   */
  const getActiveViewer = () => {
    const viewers = [
      canvas3DViewer.current,
      document.getElementById("2d-viewer"),
      pdfViewer.current,
    ];
    tracking.trackEvent({
      scout_category: "3D viewer",
      scout_feature_name: "get_active_viewer",
      scout_company: company.name,
      scout_username: user.username,
      scout_user_type: user.type,
      scout_open_full_screen: true,
      scout_date: new Date(),
    });
    return viewers.find((v) => (v ? v.style.display === "block" : null));
  };

  // Retreiving part dimensions
  let [lengthSI, widthSI, heightSI] = getBoundingBox(); // in the standard system (SI)
  let [lengthIM, widthIM, heightIM] = [mmToIn(lengthSI), mmToIn(widthSI), mmToIn(heightSI)]; // in the imperial system (IM)

  if (viewSection.current) {
    // @ts-ignore
    viewSection.current.style.display = "none";
  }

  if (disableSnapshot && viewSection.current && canvas3DViewer.current) {
    // @ts-ignore
    if (canvas3DViewer.current.style.display === "block") {
      // @ts-ignore
      viewSection.current.style.display = "block";
    }
  }

  const handleResetTheView = () => {
    fitToView();
    tracking.trackEvent({
      scout_category: "3D viewer",
      scout_feature_name: "reset_the_view",
      scout_company: company.name,
      scout_username: user.username,
      scout_user_type: user.type,
      scout_open_full_screen: true,
      scout_date: new Date(),
    });
  };

  const handleSetSolid = () => {
    setSolid();
    tracking.trackEvent({
      scout_category: "3D viewer",
      scout_feature_name: "set_solid",
      scout_company: company.name,
      scout_username: user.username,
      scout_user_type: user.type,
      scout_open_full_screen: true,
      scout_date: new Date(),
    });
  };
  const handleSetWired = () => {
    setWired();
    tracking.trackEvent({
      scout_category: "3D viewer",
      scout_feature_name: "set_wired",
      scout_company: company.name,
      scout_username: user.username,
      scout_user_type: user.type,
      scout_open_full_screen: true,
      scout_date: new Date(),
    });
  };
  return (
    <Grid item xs={12} className="viewer-component" id="viewer-grid" ref={viewerGrid}>
      {!disableSnapshot ? (
        <span
          data-tut="reactour__snapshot"
          className="snapshot-icon"
          onClick={() => {
            setIsManualSnapshot(true);
            takeSnapshot();
          }}
        >
          <PhotoCameraIcon />
        </span>
      ) : (
        <></>
      )}

      {!disableFullscreen && (
        <div>
          <span
            className="fullscreen-icon"
            data-tut="reactour__fullscreen"
            ref={fullscreenButton}
            onClick={() => {
              handleFullscreen();
            }}
          >
            <Fullscreen />
          </span>
        </div>
      )}
      {
        <div>
          <span className="view-section" id="view-section" ref={viewSection}>
            <p>
              L = {lengthSI} mm | W = {widthSI} mm | H = {heightSI} mm
              <br />L = {lengthIM} in | W = {widthIM} in | H = {heightIM} in
            </p>
            <p>{t("texture")}</p>
            <p>
              <ButtonGroup
                className="viewer-button"
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSetSolid();
                  }}
                >
                  {t("solid")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSetWired();
                  }}
                >
                  {t("wired")}
                </Button>
              </ButtonGroup>
            </p>
            <p>{t("view")}</p>
            <p>
              <ButtonGroup
                className="viewer-button"
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button variant="contained" onClick={() => handleResetTheView()}>
                  {t("resetTheView")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleIsometricView();
                  }}
                >
                  {t("isometric")}
                </Button>
              </ButtonGroup>
            </p>
            <p>
              <ButtonGroup
                className="viewer-button"
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button
                  onClick={() => {
                    handleProjectionView(1);
                  }}
                >
                  {t("top")}
                </Button>
                <Button
                  onClick={() => {
                    handleProjectionView(2);
                  }}
                >
                  {t("bottom")}
                </Button>
                <Button
                  onClick={() => {
                    handleProjectionView(3);
                  }}
                >
                  {t("left")}
                </Button>
                <Button
                  onClick={() => {
                    handleProjectionView(4);
                  }}
                >
                  {t("right")}
                </Button>
                <Button
                  onClick={() => {
                    handleProjectionView(5);
                  }}
                >
                  {t("front")}
                </Button>
                <Button
                  onClick={() => {
                    handleProjectionView(6);
                  }}
                >
                  {t("back")}
                </Button>
              </ButtonGroup>
            </p>
          </span>
        </div>
      }

      <div className="canvas-wrapper" id="canvas-wrapper" ref={canvasWrapper}>
        <div className="loader-wrapper" style={{ display: isViewerLoading ? "flex" : "none" }}>
          <Box className="loader-box">
            <CircularProgress
              // @ts-ignore
              color="grey"
            />
            <span className="loading-status">{loadingStatus}</span>
          </Box>{" "}
        </div>
        <canvas
          style={{
            background: cadViewerBackgroundColor ? cadViewerBackgroundColor : "inherit",
          }}
          className="viewer"
          id="3d-viewer"
          // @ts-ignore
          type="3d"
          ref={canvas3DViewer}
        ></canvas>
        <canvas
          className="viewer"
          id="2d-viewer"
          // @ts-ignore
          type="2d"
          width={415}
          height={300}
        ></canvas>
        <canvas id="buffer-canvas" width={415} height={300} style={{ display: "none" }}></canvas>
        <div
          id="pdf-viewer"
          // @ts-ignore
          type="pdf"
          ref={pdfViewer}
        ></div>
        {isCSVViewer && file && <CSVViewerDataGrid file={file} />}
      </div>
    </Grid>
  );
}

export default Viewer;
