import { TextField } from "@mui/material";
import React from "react";
import { useField } from "formik";

export const GenericTextField = ({ ...props }: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <TextField
      inputProps={{ tabIndex: 0 }}
      type={props.type}
      placeholder={props.placeholder}
      fullWidth
      label={props.label}
      rows={props.rows}
      multiline={!!props.rows}
      helperText={errorText || props.helperText || ""}
      error={!!errorText || !!props.isError}
      id="outlined-basic"
      variant="outlined"
      className={props.className || "form-text-field"}
      color={props.color || "secondary"} /** The focus color */
      {...field}
      onWheel={props.type === "number" ? (e: any) => e.target.blur() : undefined}
      onChange={props.onChange || field.onChange}
      disabled={props.disabled}
      onBlur={props.handleOnBlur ? props.handleOnBlur : null}
      size={props.size}
    />
  );
};
