import { RfqState } from "@next/components/state-status-tag/state-status-tag.utils";
import { selectProjectCompanyOrdersCounts } from "@next/modules/project/redux/selectors";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RfqStateCard, RfqStateCardProps } from "./rfq-state-card.component";

type Props = {
  setSelectedState: (state?: RfqState) => void;
  selectedState: string;
};

export const BuyerRfqsStateCards: React.FC<Props> = ({
  selectedState: selectedStatus,
  setSelectedState: setSelectedStatus,
}) => {
  const counts = useSelector(selectProjectCompanyOrdersCounts);
  const statusCounts = counts?.status_counts;

  const statusCards = useMemo<RfqStateCardProps[]>(
    () => [
      {
        state: RfqState.ALL,
        count: counts?.in_progress_count || 0,
      },
      {
        state: RfqState.URGENT,
        count: statusCounts?.urgent_count || 0,
      },
      {
        state: RfqState.ON_TRACK,
        count: statusCounts?.on_track_count || 0,
      },
      {
        state: RfqState.AT_RISK,
        count: statusCounts?.at_risk_count || 0,
      },
      {
        state: RfqState.BLOCKED,
        count: statusCounts?.blocked_count || 0,
      },
      {
        state: RfqState.READY_FOR_DECISION,
        count: statusCounts?.ready_for_decision_count || 0,
      },
    ],
    [statusCounts, counts]
  );

  return (
    <>
      {statusCards.map((card) => (
        <RfqStateCard
          key={card.state}
          state={card.state}
          selected={selectedStatus === card.state}
          count={card.count}
          onClick={() => setSelectedStatus(card.state)}
        />
      ))}
    </>
  );
};
