import React from "react";

import { Avatar, Button, Grid, Typography, TypographyProps } from "@mui/material";

type Props = {
  onClick?: () => void;
  src: string;
  variant?: TypographyProps["variant"];
  text: string;
  subtext?: string;
  variantSubText?: TypographyProps["variant"];
  classNameSubText?: string;
  buttonTheme?: string;
  className?: string;
};
export const InformationPage = ({
  onClick,
  src,
  variant,
  text,
  subtext,
  variantSubText,
  classNameSubText,
  buttonTheme,
  className,
}: Props) => {
  return (
    <div className="c-question-ask-validation">
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item xs={6} className="c-question-ask-validation--img">
          <Avatar alt="email sent logo" src={src} className="c-question-ask-validation--logo" />
        </Grid>
        <Grid item xs={4} className="c-question-ask-validation--text">
          <Typography align="center" variant={variant} className={className}>
            {text}
          </Typography>
          {subtext && (
            <Typography align="center" variant={variantSubText} className={classNameSubText}>
              {subtext}
            </Typography>
          )}
        </Grid>
        {buttonTheme && (
          <Grid item className="c-question-ask-validation--btn">
            <Button
              variant="contained"
              color="primary"
              onClick={onClick}
              className="c-question-ask-validation--btn--background"
            >
              {buttonTheme}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
