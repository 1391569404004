import React from "react";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { Box, Chip, Link, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { downloadFile } from "../../../helpers/download";
import { RootState } from "store";

type Props = {
  file: any;
  linkDisplayName?: string;
  rfqPk: number;
  rfqName: string;
  isPurchaseOrder?: boolean;
  category: string;
  featureName: string;
};

//Use this function to display list of upload  part
export const RenderUploads = ({
  file,
  linkDisplayName = null,
  rfqPk,
  rfqName,
  isPurchaseOrder,
  category,
  featureName,
}: Props) => {
  const tracking = useTracking();
  const profile = useSelector((state: RootState) => state.profile);

  const handleDownloadClick = (e, fileUrl) => {
    e.stopPropagation();
    downloadFile(e, fileUrl);
    tracking.trackEvent({
      scout_category: category,
      scout_feature_name: featureName,
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["username"],
      scout_user_type: profile["user"]["type"],
      scout_rfq_pk: rfqPk,
      scout_rfq_name: rfqName,
      scout_file_name: file.file_name,
      scout_date: new Date(),
    });
  };
  return (
    <List disablePadding className="c-render-uploads">
      {file.file_name ? (
        <ListItem key={file.pk}>
          {!isPurchaseOrder && (
            <ListItemIcon>
              <Chip size="small" className="c-render-uploads--chip" label={file.file_extension} />
            </ListItemIcon>
          )}
          <ListItemText
            className="c-render-uploads--list-item-text"
            secondary={
              <Box
                ml={"8px"}
                textAlign={isPurchaseOrder ? "center" : "left"}
                fontWeight="fontWeightRegular"
                fontSize={14}
                className={
                  isPurchaseOrder ? "list-item-text--colorBlue" : "list-item-text--colorBlack"
                }
              >
                {file.file_url ? (
                  <Link download onClick={(e) => handleDownloadClick(e, file.file_url)}>
                    {linkDisplayName || file.file_name}
                  </Link>
                ) : (
                  <span style={{ opacity: 0.4 }}>{linkDisplayName || file.file_name}</span>
                )}
              </Box>
            }
          />
        </ListItem>
      ) : (
        ""
      )}
    </List>
  );
};
