import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import { npsActions } from "../../services/nps";
import { modalsActions } from "../../services/modals/modals.actions";
import { RootState } from "store";

const Nps = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => state.profile);
  const isSubmitRatingLoading = useSelector((state: RootState) => state.nps.isSubmitRatingLoading);
  const [rating, setRating] = useState(null);
  const { t } = useTranslation();
  // NPS dialog dismiss action
  const handleClose = () => {
    dispatch(modalsActions.hideModal("isNPSOpen"));
    dispatch(npsActions.sendNPS(t, profile["token"], profile["user"]["nps_token"], true));
  };
  // handle score change
  const handleChange = (event) => {
    setRating(Number(event.target.value));
  };
  // handle send button click
  // sendNPS @params: t, token, nps_token, has_dismissed flag, score
  const handleSubmit = () => {
    dispatch(npsActions.sendNPS(t, profile["token"], profile["user"]["nps_token"], false, rating));
  };

  return (
    <div>
      <Grid container className="nps-container" display="flex">
        <Grid container item xs={11} className="nps-container--content">
          <Grid item xs={12} md={3}>
            <Typography className="nps-container--description">
              {profile["user"]["type"] === "DO"
                ? t("nps:buyerNpsDescription")
                : t("nps:supplierNpsDescription")}
            </Typography>
          </Grid>
          <Grid item md={6} lg={5} className="nps-container--rating">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="rating"
                value={rating}
                onChange={handleChange}
                name="rating"
                row
              >
                {[...Array(10)].map((radio, i) => {
                  const ratingIndex = i + 1;
                  return (
                    <FormControlLabel
                      value={ratingIndex}
                      control={<Radio color="primary" />}
                      label={ratingIndex}
                      name="rating"
                      labelPlacement="top"
                      className="nps-container--radio-group"
                    />
                  );
                })}
              </RadioGroup>
              <Grid container direction="row" className="like-dosage">
                <Grid item xs={3}>
                  <Typography variant="body2" className="like-dosage--dislike">
                    {t("nps:unLike")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" className="like-dosage--like">
                    {t("nps:like")}
                  </Typography>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!rating || isSubmitRatingLoading ? true : false}
              onClick={() => handleSubmit()}
            >
              {t("nps:sendButton")}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={1} className="nps-container--icon">
          <IconButton aria-label="close" onClick={() => handleClose()} size="large">
            <CloseIcon className="nps-container--close" />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default Nps;
