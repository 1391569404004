/**
 * AutocompleteOptions.jsx
 *
 * This component is a reusable component. is a material ui autocomplete dropdown.
 * we use it the form to filter data
 *
 */

import React from "react";

import { fieldToTextField } from "formik-material-ui";
import Autocomplete from "@mui/material/Autocomplete";

import { makeStyles } from "@mui/styles";

import { GenericAutoCompleteTextField } from "../generic-autocomplete-text-field/generic-autocomplete-text-field.component";
import { TextFieldProps } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiAutocomplete-option": {
      marginLeft: "-16px",
    },
  },
  clearIndicator: {
    "& span": {
      "& svg": {
        "& path": {
          d: "path('M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z')",
        },
      },
    },
  },
}));

type Props = {
  textFieldProps: any;
  staticsData: any;
  label: string;
  handleSelectedRfq?: (value: any) => void;
  form: any;
} & TextFieldProps;

// this component is use to categorize and filter statics data
const AutocompleteOptions = ({
  textFieldProps,
  staticsData,
  label,
  handleSelectedRfq,
  ...props
}: Props) => {
  let options;
  options = staticsData.map((option) => {
    const byType = option.type.toUpperCase();
    return {
      byType: /[0-9]/.test(byType) ? Array(5).fill("\xa0").join("") + "0-9" : byType.trim(),
      ...option,
    };
  });
  const classes = useStyles();

  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { ...field } = fieldToTextField(props as any);
  const { name } = field;

  return (
    <Autocomplete
      id={name}
      options={options.sort((a, b) => -b.byType.localeCompare(a.byType))}
      groupBy={(option: any) => option.byType}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option || "";
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue || "";
        }
        // Regular option
        return option.name || "";
      }}
      {...props}
      {...field}
      onBlur={() => setTouched({ [name]: true })}
      onChange={(_, newValue) => {
        if (typeof newValue === "string") {
          setFieldValue(name, newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setFieldValue(name, newValue.inputValue);
        } else {
          setFieldValue(name, newValue);
          if (handleSelectedRfq) handleSelectedRfq(newValue);
        }
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      isOptionEqualToValue={(item, current) => item.name === current.name}
      style={{ width: "100%" }}
      classes={{
        // @ts-ignore
        clearIndicatorDirty: classes.clearIndicator,
      }}
      renderInput={(params) => (
        <GenericAutoCompleteTextField {...params} {...textFieldProps} name={name} label={label} />
      )}
    />
  );
};

export default AutocompleteOptions;
