//this is the component we use to add a new part to the workspace

// general imports
import React, { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

// other imports
import { backendUrl } from "urls";
import { handleResponse } from "helpers/responseHandling";

// Component imports
import PartViewer from "components/part-dialog/viewer/viewer.component";
import PartForm from "components/part-dialog/form/form.component";
import PartAttachments from "components/part-dialog/attachments/attachments.component";
import AddPurchaseOrder from "components/add-purchase-order/add-purchase-order.component";
import uploadSvg from "assets/img/Upload.svg";

// Material ui component and icons
import { Grid } from "@mui/material";
import { axiosApi } from "@next/api";

const updateUploadPreview = (token, filePk, img) => {
  return axiosApi
    .patch(`${backendUrl.uploadPart}${filePk}`, {
      file_preview: img,
    })
    .then(handleResponse);
};

type Props = {
  attachments: any[];
  isEditMode?: boolean;
  token?: string;
  partDetails: any;
  isPurchaseOrder?: boolean;
  isProject?: boolean;
  handleClose: () => void;
  isRfqPartEdit?: boolean;
};

export const AddPart: FC<Props> = (props) => {
  const { t } = useTranslation("workspace");
  const [attachments, setAttachments] = useState(props.attachments);
  const [activeFile, setActiveFile] = useState(null);
  const [activeFilePk, setActiveFilePk] = useState(null);
  const [hasAttachment, setHasAttachment] = useState(false);
  const [selectedPk, setSelectedPk] = useState(null);
  const [itemSnapshotPreview, setItemSnapshotPreview] = useState(null);
  const [thumbFilePk, setThumbFilePk] = useState(null);

  const onUploadSelectionChange = (attachment, attachments) => {
    if (attachments && attachments.length === 0) {
      setHasAttachment(false);
      setActiveFile(null);
      return;
    }
    if (!attachment) {
      return;
    }
    setSelectedPk(attachment.pk);
    // Update top level attachments
    setAttachments(attachments);
    setHasAttachment(true);
    if (props.isEditMode) {
      setActiveFile(attachment.file_url);
    } else {
      setActiveFile(attachment.file);
    }

    setActiveFilePk(attachment.pk);
  };

  const takeSnapshot = (b64) => {
    setItemSnapshotPreview(b64);
    updateUploadPreview(props.token, selectedPk, b64);
    setThumbFilePk(selectedPk);
  };

  useEffect(() => {
    if (!props.isEditMode) {
      setAttachments(props.attachments);
    } else {
      setItemSnapshotPreview(props.partDetails.preview && props.partDetails.preview.file_url);
      setThumbFilePk(props.partDetails.preview && props.partDetails.preview.pk);
      setAttachments(props.partDetails.part_uploads_list);
    }
  }, [props.attachments]);

  return (
    <React.Fragment>
      {props.isPurchaseOrder ? (
        <AddPurchaseOrder
          {...props}
          attachments={attachments}
          onUploadSelectionChange={onUploadSelectionChange}
          isPurchaseOrder={props.isPurchaseOrder}
          uploadSvg={uploadSvg}
          t={t}
          isProject={props.isProject}
        />
      ) : (
        <Grid container direction="row" xs={12} className="c-main-container">
          <Grid container item xs={6} direction="column" className="c-right-border">
            <Grid item xs={12} className="c-bottom-border">
              <PartViewer
                isEditMode={props.isEditMode}
                src={activeFile}
                activeFilePk={activeFilePk}
                onSnapshot={takeSnapshot}
              />
            </Grid>
            <Grid item xs={12} className="c-add-more-files-component">
              <PartAttachments
                {...props}
                height={"140px"}
                attachments={attachments}
                onAttachmentSelectionChange={onUploadSelectionChange}
                scoutCategory="attachments"
                scoutFeatureName="select_part_file"
                scoutDeleteFeatureName="delete_part_file"
              />
            </Grid>
          </Grid>
          <Grid item xs={6} className="c-form">
            <PartForm
              {...props}
              attachments={attachments}
              hasAttachment={hasAttachment}
              thumbSrc={itemSnapshotPreview}
              thumbFilePk={thumbFilePk}
              isEditMode={props.isEditMode}
              onPartSubmitSuccess={props.handleClose}
              t={t}
              isRfqPartEdit={props.isRfqPartEdit}
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};
