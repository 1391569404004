import React from "react";
import { Chip, Theme, Tooltip, alpha } from "@mui/material";
import { useTranslation } from "react-i18next";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { createStyles, makeStyles } from "@mui/styles";

type Variant = "warning" | "success" | "null";

const useStyles = makeStyles<Theme, Props>((theme) => {
  const getColor = (variant: Variant) => (variant === "warning" ? "warning" : "success");

  return createStyles({
    tag: {
      cursor: "unset",
      textOverflow: "ellipsis",
      overflow: "hidden",
      color: ({ variant }) => theme.palette[getColor(variant)].dark,
      backgroundColor: ({ variant }) => alpha(theme.palette[getColor(variant)].main, 0.1),
      fontSize: theme.typography.caption.fontSize,

      "& .MuiChip-icon": {
        color: ({ variant }) => theme.palette[getColor(variant)].main,
        fontSize: 20,
        marginRight: -8,
      },
    },
  });
});

interface Props {
  variant: Variant;
}

export const AIParseQuoteStatusTag: React.FC<Props> = ({ variant }) => {
  const { t } = useTranslation();
  const classes = useStyles({ variant });

  const labelKey =
    variant === "warning" ? "quotation:aiExtractWarning" : "quotation:aiExtractSuccess";

  const icon =
    variant === "warning" ? (
      <AutoAwesomeIcon color="warning" />
    ) : (
      <AutoAwesomeIcon color="success" />
    );

  if (variant === "null") {
    return null;
  }

  return <Chip icon={icon} label={t(labelKey)} className={classes.tag} />;
};

export const DiscrepancyTag: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles({ variant: "warning" });

  return (
    <Tooltip
      title={t("quotation:discrepancyWarningMessage")}
      componentsProps={{
        tooltip: {
          sx: {
            textAlign: "center",
          },
        },
      }}
    >
      <Chip
        label={t("quotation:discrepancyWarning")}
        className={classes.tag}
        style={{ cursor: "pointer" }}
      />
    </Tooltip>
  );
};
