import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";
import { Avatar, Box, ClickAwayListener, Grid, Tooltip } from "@mui/material";
import Rating from "@mui/material/Rating";
import Skeleton from "@mui/material/Skeleton";
import StarIcon from "@mui/icons-material/Star";
import IconButton from "@mui/material/IconButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { formatDate } from "helpers/formatDate";
import { workspaceActions } from "services/workspace";
import { SellerInfoPopover } from "../../seller-info-popover/seller-info-popover.component";
import { getCompany, getUser } from "services/profile/profile.selectors";
import { openProfileInNewTab } from "@next/utils/browserUtils";
import QuotationPriceAndLeadTime from "@next/modules/workspace/components/quotation-price-and-lead-time";
import { AppTheme } from "layout/AppTheme";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import StatusVariantTag from "@next/modules/workspace/components/status-variant-tag/status-variant-tag";
import { PartsStatusProgress } from "@next/modules/workspace/components/buyer-rfqs-table/buyer-rfqs-table.columns";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { RequestType } from "@next/modules/workspace/components/rfq-creation-form/rfq-creation.form.definitions";
import { AIParseQuoteStatusTag } from "@next/modules/workspace/components/ai-parse-quote-status-tag/ai-parse-quote-status-tag";
import { RootState } from "store";

type Props = {
  token: string;
  quote: any;
  setAnchorEl: Function;
  anchorEl: Function;
  quotePk: number;
};

export const OffersSummary = ({ token, quote, setAnchorEl, anchorEl, quotePk }: Props) => {
  const dispatch = useDispatch();
  const tracking = useTracking();

  const { t } = useTranslation();

  const user = useSelector(getUser);

  const company = useSelector(getCompany);
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const rfq = useSelector(getRfqDetails);
  const [popoverPosition, setPopoverPosition] = useState({
    popoverLeft: 0,
    popoverTop: 0,
  });

  //Company score state
  const { companyRatingsScores, isCompanyRatingsScoreLoading } = useSelector(
    (state: RootState) => state.companyRatings
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBookmarkSupplier = (event) => {
    event.stopPropagation();
    const payload = { is_bookmarked: !quote.is_bookmarked };
    dispatch(workspaceActions.editBookmarkQuotation(token, quote.pk, payload, t));
  };

  const openPopup = Boolean(anchorEl);

  const popupId = openPopup ? "seller-info-popover" : undefined;

  const pdf_parsing_result =
    quote.quotation_uploads_list[quote.quotation_uploads_list.length - 1]?.pdf_parsing_result;

  const sellerInfoRef = React.createRef<HTMLDivElement>();
  const handleRatingClick = (event) => {
    event.stopPropagation();
    openProfileInNewTab(quote?.company_info?.company_name, quote?.company_info?.company_uuid);
  };

  const handleRatingMouseOver = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (sellerInfoRef && sellerInfoRef.current) {
      const left = sellerInfoRef.current.getBoundingClientRect().left;
      const top = sellerInfoRef.current.getBoundingClientRect().top;
      setPopoverPosition({
        ...popoverPosition,
        popoverLeft: left,
        popoverTop: top,
      });
    }
    tracking.trackEvent({
      scout_category: "feature",
      scout_feature_name: "seller_rating",
      scout_company: company?.name,
      scout_username: user?.name,
      scout_user_type: user?.type,
      scout_date: new Date(),
    });
  };

  const hasQuotationTemplate = useMemo(() => {
    return Boolean(quote?.rfq_info?.quotation_extra_fields?.length > 0);
  }, [quote]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className="c-offer-summary"
      spacing={2}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Grid item style={{ width: 540 }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            aria-describedby={popupId}
            ref={sellerInfoRef}
          >
            <Grid item>
              <Avatar
                alt="supplier image"
                src={quote.company_info.company_picture}
                className="c-offer-summary--avatar"
              />
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center">
                <Tooltip title={quote.company_info.company_name}>
                  <Box
                    fontWeight="fontWeightMedium"
                    fontSize={20}
                    color="#000000DE"
                    className="c-offer-summary--company-name"
                    maxWidth="350px"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {quote.company_info.company_name}
                  </Box>
                </Tooltip>
                {hasViewerRole ? null : (
                  <Box
                    ml={1}
                    fontSize={12}
                    onClick={handleRatingClick}
                    display="flex"
                    alignItems="center"
                    textAlign="left"
                    color="#2680EB"
                    className="c-offer-summary--full-profile"
                  >
                    {t("sellerInfosPopover:fullProfile")}
                    <ArrowForwardIosIcon className="c-offer-summary--arrow-forward-icon" />
                  </Box>
                )}
              </Box>
              {isCompanyRatingsScoreLoading ? (
                <Box display="flex" alignItems="center" mt={1}>
                  <Skeleton animation="wave" height={"100%"} width={24} />
                  <Skeleton animation="wave" height={"100%"} width={60} style={{ marginLeft: 8 }} />
                  <Skeleton animation="wave" height={"100%"} width={90} style={{ marginLeft: 8 }} />
                </Box>
              ) : (
                <>
                  {false && //temporarily hide rating AAC-5473
                  companyRatingsScores &&
                  Object.keys(companyRatingsScores).length &&
                  companyRatingsScores[quotePk] &&
                  companyRatingsScores[quotePk]["overall_score"] ? (
                    <Box display="flex" alignItems="center" mt={1}>
                      <Box
                        fontSize={16}
                        color={AppTheme.palette.primary.main}
                        letterSpacing={0.5}
                        fontWeight="bold"
                      >
                        {companyRatingsScores[quotePk] &&
                          companyRatingsScores[quotePk]["overall_score"].toFixed(1)}
                      </Box>
                      <Box ml={1} onMouseOver={handleRatingMouseOver}>
                        <Rating
                          color={AppTheme.palette.primary.main}
                          name="read-only"
                          value={
                            companyRatingsScores[quotePk]
                              ? companyRatingsScores[quotePk]["overall_score"].toFixed(1)
                              : ""
                          }
                          precision={0.1}
                          readOnly
                        />
                      </Box>

                      <Box ml={1} fontSize={16} letterSpacing={0.5} color="#757575">
                        {`( ${
                          companyRatingsScores[quotePk] &&
                          companyRatingsScores[quotePk]["ratings_count"]
                        } ${t("sellerInfosPopover:ratings")}${
                          companyRatingsScores[quotePk] &&
                          companyRatingsScores[quotePk]["ratings_count"] > 1
                            ? "s"
                            : ""
                        } )`}
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              )}
              <Box fontWeight="fontWeightRegular" fontSize={14} color="#00000099" mt={1}>
                {formatDate(quote.created_at)}
              </Box>
            </Grid>
          </Grid>
          <SellerInfoPopover
            open={openPopup}
            anchorEl={anchorEl}
            handleClose={handleClose}
            sellerData={quote.company_info}
            popoverPosition={popoverPosition}
            ratingScores={
              companyRatingsScores &&
              Object.keys(companyRatingsScores).length &&
              companyRatingsScores[quotePk]
                ? companyRatingsScores[quotePk]["rating_scores"]
                : {}
            }
            t={t}
          />
        </Grid>
      </ClickAwayListener>
      <Grid item>
        <Box display="flex" flexDirection="column" alignItems="center" gap="8px">
          {quote.is_awarded ? (
            <StatusVariantTag withBg variant="awarded" />
          ) : quote.is_revision_requested ? (
            <StatusVariantTag withBg variant="revision-requested" />
          ) : pdf_parsing_result ? (
            <AIParseQuoteStatusTag
              variant={
                pdf_parsing_result.unmatched_rfq_part_pks?.length === 0
                  ? "success"
                  : pdf_parsing_result.unmatched_rfq_part_pks?.length > 0
                    ? "warning"
                    : "null"
              }
            />
          ) : null}

          {rfq?.parts?.length && rfq?.is_no_quote_detected ? (
            <PartsStatusProgress
              totalParts={rfq.parts.length}
              quotedParts={quote.quoted_parts_count || 0}
            />
          ) : null}
        </Box>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justifyItems="center">
          {rfq?.request_type !== RequestType.RFI && (
            <Grid item>
              <QuotationPriceAndLeadTime
                quotation={quote}
                showLeadTime={!hasQuotationTemplate}
                showCurrencyRate
              />
            </Grid>
          )}
          <Grid item>
            <Tooltip
              title={
                <Box style={{ whiteSpace: "pre-line" }}>
                  {t("workspaceNext:rfqDrawer:bookmarkMsg")}
                </Box>
              }
            >
              <IconButton onClick={handleBookmarkSupplier}>
                {quote.is_bookmarked ? <StarIcon style={{ color: "gold" }} /> : <StarBorderIcon />}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
