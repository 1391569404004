// dashboardSlice.ts
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AddCompanyProfileViewInput,
  BuyerProfile,
  BuyerProfilePart,
  CompanyProfileAnalytics,
  CompanyProfileViews,
  DashboardState,
  FetchBuyerProfileInput,
  FetchBuyerProfilePartInput,
  FetchBuyerProfilesInput,
  FetchCompanyProfileViewsInput,
  SupplierOnboardingStatus,
} from "./types";
import { ProfileConnectionState } from "@next/modules/marketplace/components/profile-connect-button/profile-connect-button";
import { Connection } from "@next/modules/profile/redux";
import { OnboardingSteps } from "../components/seller-dashboard-onboarding-premium/seller-dashboard-onboarding-premium";
import { dashboardService } from "../services/dashboard-services";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { getCompany } from "services/profile/profile.selectors";
import { selectSupplierOnboardingStatus } from "./selectors";
import { RootState } from "store";
import { ThunkStatus } from "helpers/status.enhancer";

// ----- Async Thunks -----

export const fetchCompanyProfileAnalytics = createAsyncThunk<CompanyProfileAnalytics, void>(
  "dashboard/fetchCompanyProfileAnalytics",
  async () => {
    const response = await dashboardService.fetchCompanyProfileAnalytics();
    return response.data;
  }
);

export const fetchCompanyProfileViews = createAsyncThunk<
  { results: CompanyProfileViews[]; count: number; query: string },
  FetchCompanyProfileViewsInput
>("dashboard/fetchCompanyProfileViews", async (payload) => {
  const response = await dashboardService.fetchCompanyProfileViews(payload);
  return { ...response.data, query: payload.query };
});

export const addCompanyProfileView = createAsyncThunk<void, AddCompanyProfileViewInput>(
  "dashboard/addCompanyProfileView",
  async (payload) => {
    await dashboardService.addCompanyProfileView(payload);
  }
);

export const fetchBuyerProfiles = createAsyncThunk<
  { results: BuyerProfile[]; count: number },
  FetchBuyerProfilesInput
>("dashboard/fetchBuyerProfiles", async (payload) => {
  const response = await dashboardService.fetchBuyerProfiles(payload);
  return response.data;
});

export const fetchBuyerProfile = createAsyncThunk<BuyerProfile, FetchBuyerProfileInput>(
  "dashboard/fetchBuyerProfile",
  async (payload) => {
    const response = await dashboardService.fetchBuyerProfile(payload);
    return response.data;
  }
);

export const fetchBuyerProfilePart = createAsyncThunk<
  BuyerProfilePart[],
  FetchBuyerProfilePartInput
>("dashboard/fetchBuyerProfilePart", async (payload) => {
  const response = await dashboardService.fetchBuyerProfilePart(payload);
  return response.data;
});

export const fetchSupplierOnboardingStatus = createAsyncThunk<SupplierOnboardingStatus, void>(
  "dashboard/fetchSupplierOnboardingStatus",
  async () => {
    const response = await dashboardService.fetchSupplierOnboardingStatus();
    return response.data;
  }
);

export const showPopUpOnboarding = createAsyncThunk<void, OnboardingSteps, { state: RootState }>(
  "dashboard/showPopUpOnboarding",
  async (step, { dispatch, getState }) => {
    const state = getState();
    const company = getCompany(state);
    if (!company || company.plan_name !== "premium") return;

    const showPopup = (status: SupplierOnboardingStatus) => {
      let stateKey: keyof SupplierOnboardingStatus;
      switch (step) {
        case OnboardingSteps.SENDING_CONNECTION_REQUEST:
          stateKey = "company_connection_done";
          break;
        case OnboardingSteps.VISITING_NEWSFEED:
          stateKey = "newsfeed_viewed";
          break;
        case OnboardingSteps.VIEWING_REQUEST_MARKETPLACE:
          stateKey = "marketplace_viewed";
          break;
        case OnboardingSteps.UPDATING_COMPANY_PROFILE:
          stateKey = "company_profile_complete";
          break;
        default:
          throw new Error("Invalid Onboarding Step");
      }
      if (status && status[stateKey] === false) {
        const updatedStatus: SupplierOnboardingStatus = {
          ...status,
          [stateKey]: true,
        };
        dispatch(dashboardSlice.actions.fetchSupplierOnboardingStatusSuccess(updatedStatus));
        dispatch(
          modalsActions.showModal({
            key: SharedModalTypes.SUPPLIER_ONBOARDING_MODAL,
          })
        );
      }
    };

    let supplierStatus = selectSupplierOnboardingStatus(state);
    if (!supplierStatus) {
      const resultAction = await dispatch(fetchSupplierOnboardingStatus());
      if (fetchSupplierOnboardingStatus.fulfilled.match(resultAction)) {
        supplierStatus = resultAction.payload;
        showPopup(supplierStatus);
      }
    } else {
      showPopup(supplierStatus);
    }
  }
);

// ----- Slice Definition -----

const initialState: DashboardState = {
  companyProfileAnalytics: undefined,
  companyProfileViews: undefined,
  companyProfileViewsCount: 0,
  companyProfileViewsLastQuery: undefined,
  buyerProfiles: undefined,
  buyerProfilesCount: 0,
  buyerProfile: undefined,
  buyerProfilePart: undefined,
  supplierOnboardingStatus: undefined,

  companyProfileAnalyticsStatus: ThunkStatus.IDLE,
  companyProfileViewsStatus: ThunkStatus.IDLE,
  addCompanyProfileViewStatus: ThunkStatus.IDLE,
  buyerProfilesStatus: ThunkStatus.IDLE,
  buyerProfileStatus: ThunkStatus.IDLE,
  buyerProfilePartStatus: ThunkStatus.IDLE,
  supplierOnboardingStatusStatus: ThunkStatus.IDLE,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateBuyerProfileConnection: (
      state,
      action: PayloadAction<{
        buyerProfileId?: number;
        connection: Connection | null;
      }>
    ) => {
      const newConnectionState =
        action.payload.connection === null
          ? ProfileConnectionState.NOT_CONNECTED
          : action.payload.connection.is_approved
            ? ProfileConnectionState.CONNECTED
            : ProfileConnectionState.PENDING;

      if (state.buyerProfile) {
        state.buyerProfile = {
          ...state.buyerProfile,
          connection: action.payload.connection,
          connection_status: newConnectionState,
        };
      }
      if (action.payload.buyerProfileId && state.companyProfileViews) {
        state.companyProfileViews = state.companyProfileViews.map((item) => {
          if (item.viewed_by_company?.id === action.payload.buyerProfileId) {
            return {
              ...item,
              connection: action.payload.connection,
              connection_status: newConnectionState,
            };
          }
          return item;
        });
      }
    },
    fetchSupplierOnboardingStatusSuccess: (
      state,
      action: PayloadAction<SupplierOnboardingStatus>
    ) => {
      state.supplierOnboardingStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyProfileAnalytics.fulfilled, (state, action) => {
        state.companyProfileAnalytics = action.payload;
      })
      .addCase(fetchCompanyProfileViews.fulfilled, (state, action) => {
        state.companyProfileViews = action.payload.results;
        state.companyProfileViewsCount = action.payload.count;
      })
      .addCase(addCompanyProfileView.fulfilled, (state) => {
        state.addCompanyProfileViewStatus = ThunkStatus.IDLE;
      })
      .addCase(fetchBuyerProfiles.fulfilled, (state, action) => {
        state.buyerProfiles = action.payload.results;
        state.buyerProfilesCount = action.payload.count;
      })
      .addCase(fetchBuyerProfile.fulfilled, (state, action) => {
        state.buyerProfile = action.payload;
      })
      .addCase(fetchBuyerProfilePart.fulfilled, (state, action) => {
        state.buyerProfilePart = action.payload;
      })
      .addCase(fetchSupplierOnboardingStatus.fulfilled, (state, action) => {
        state.supplierOnboardingStatus = action.payload;
      });
  },
});

export const dashboardActions = {
  ...dashboardSlice.actions,
  fetchCompanyProfileAnalytics,
  fetchCompanyProfileViews,
  addCompanyProfileView,
  fetchBuyerProfiles,
  fetchBuyerProfile,
  fetchBuyerProfilePart,
  fetchSupplierOnboardingStatus,
  showPopUpOnboarding,
};

export default dashboardSlice.reducer;
